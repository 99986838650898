import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { closeLoading, showLoading } from "../../common";
import { uploadFile } from "../../ImageUploadHelper";
import queries from "../../queries";

const UPLOAD_IMAGE = gql`
  mutation ($files: [Upload!]!) {
    uploadFiles(files: $files) {
      success
    }
  }
`;

function ImageUploaderOptions({
  attachImageURLsToOptions,
  options,
  optionIndex,
  valueIndex,
}) {
  const [images, setImages] = useState([
    ...options[optionIndex]["values"][valueIndex]["images"],
  ]);

  async function uploadImagesToAssets(images) {
    try {
      showLoading("Uploading images...");
      const newImageUrls = [];
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const url = await uploadFile(image);
        newImageUrls.push(url);
      }
      closeLoading();
      let arrOptions = [...options];
      let optionValueImages = [
        ...arrOptions[optionIndex]["values"][valueIndex]["images"],
        ...newImageUrls,
      ];
      arrOptions[optionIndex]["values"][valueIndex]["images"] =
        optionValueImages;
      attachImageURLsToOptions(arrOptions);
    } catch (error) {
      console.error(error.message);
      if (typeof error == "string") error = { message: error };
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  }

  function onImageChange({ target: { validity, files } }) {
    uploadImagesToAssets(files);
  }

  function handleRemoveImage(imgSrc) {
    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      text: "You are removing the image from being uploaded",
      confirmButtonText: "Yes",
      showDenyButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        let arrOptions = [...options];
        let optionValueImages = arrOptions[optionIndex]["values"][valueIndex][
          "images"
        ].filter((i) => i !== imgSrc);
        arrOptions[optionIndex]["values"][valueIndex]["images"] = [
          ...optionValueImages,
        ];
        attachImageURLsToOptions(arrOptions);
      }
    });
  }

  return (
    <div>
      <input type="file" multiple accept="image/*" onChange={onImageChange} />
      <div className="container">
        <div className="row justify-content-center">
          {options[optionIndex]["values"][valueIndex]["images"].map(
            (imgSrc) => (
              <div className="col-sm-12 col-md-4 row position-relative justify-content-center border m-2 p-2">
                <img className="col" src={imgSrc} />
                <Link
                  className="remove-image position-absolute d-flex align-items-center justify-content-center h-100"
                  to={{}}
                  onClick={() => handleRemoveImage(imgSrc)}
                >
                  <i className="fa fa-trash"></i>
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageUploaderOptions;
