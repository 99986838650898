import { useQuery } from "@apollo/client";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
// import SocialLinks from "./extra/SocialLinks";
import queries from "../queries";
// import { getPathFromJewelry } from "./Jewelry";
import { getCurrCurrency, setPageTitle } from "../common";
import { useEffect } from "react";
// import AddToCart from "./mini/AddToCart";
// import AddToWishlist from "./mini/AddToWishlist";
import { useState } from "react";
import { ImportMainJs } from "../helper/ImportScript";

function QuickView({ productId }) {
  const navigate = useNavigate();
  const [activeImage, setActiveImage] = useState();
  const { data, loading, error } = useQuery(queries.products.GET_PRODUCT, {
    variables: {
      productId: productId ? productId : "",
    },
  });
  useEffect(() => {
    if (data != null) ImportMainJs();
    if (data?.getProduct?.image?.length > 0) {
      setActiveImage(data?.getProduct?.image[0]);
      setPageTitle(data?.getProduct?.name);
    }
  }, [data]);

  return (
    <div className="modal modal-login fade" id="modal-login">
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <button className="modal-close" data-dismiss="modal"></button>
          <div className="modal-body">
            <form
              className="rd-mailform"
              data-form-output="form-output-global"
              data-form-type="contact"
              method="post"
              action="bat/rd-mailform.php"
            >
              <div className="form-wrap">
                <label
                  className="form-label form-label-outside"
                  htmlFor="login-name"
                >
                  Name *
                </label>
                <input
                  className="form-input"
                  id="login-name"
                  type="text"
                  name="name"
                  data-constraints="@Required"
                  placeholder="Your name"
                />
              </div>
              <div className="form-wrap">
                <label
                  className="form-label form-label-outside"
                  htmlFor="login-phone"
                >
                  Phone *
                </label>
                <input
                  className="form-input"
                  id="login-phone"
                  type="text"
                  name="phone"
                  data-constraints="@Required @PhoneNumber"
                  placeholder="Your phone number"
                />
              </div>
              <div className="form-button">
                <button className="btn btn-gray-800 btn-block" type="submit">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    // <div className="modal fade modal-wrapper" id="exampleModalCenter">
    //   <div className="modal-dialog modal-dialog-centered" role="document">
    //     <div className="modal-content">
    //       <div className="modal-body">
    //         <button
    //           type="button"
    //           className="close"
    //           data-bs-dismiss="modal"
    //           aria-label="Close"
    //         >
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //         <div className="modal-inner-area sp-area row">
    //           <div className="container">
    //             <div className="sp-nav">
    //               <div className="row">
    //                 <div className="col-lg-5 col-md-5">
    //                   <div className="sp-img_area">
    //                     <div>
    //                       <img
    //                         src={activeImage}
    //                         data-zoom-image={activeImage}
    //                         alt={data?.getProduct?.name}
    //                       />
    //                     </div>
    //                     <div
    //                       id="gallery"
    //                       className="sp-img_slider row"
    //                       hidden={true}
    //                     >
    //                       {data?.getProduct?.image?.map((img, inx) => {
    //                         return (
    //                           <a
    //                             href="javascript:void(0);"
    //                             className={inx == 0 ? "active col-3" : "col-3"}
    //                             data-image={img}
    //                             data-zoom-image={img}
    //                             onClick={(e) => {
    //                               e.preventDefault();
    //                               setActiveImage(img);
    //                             }}
    //                           >
    //                             <img src={img} alt={data?.getProduct?.name} />
    //                           </a>
    //                         );
    //                       })}
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-7 col-md-7">
    //                   <div className="sp-content">
    //                     <div className="sp-heading mb-3 mt-2">
    //                       <h3>
    //                         <a href="javascript:void(0);">
    //                           {data?.getProduct?.name}
    //                         </a>
    //                       </h3>
    //                     </div>
    //                     <div className="sp-heading mb-3 mt-2">
    //                       <span
    //                         style={{
    //                           textDecoration: "line-through",
    //                           color: "GrayText",
    //                         }}
    //                       >
    //                         {getCurrCurrency()}
    //                         {data?.getProduct?.from_price}
    //                       </span>
    //                       <span
    //                         className="mx-2"
    //                         style={{ fontSize: "1.5rem", color: "#64aeac" }}
    //                       >
    //                         {getCurrCurrency()}
    //                         {data?.getProduct?.discounted_from_price}
    //                       </span>
    //                     </div>
    //                     <div className="sp-content mb-4">
    //                       <span>{data?.getProduct?.short_description}</span>
    //                     </div>
    //                     <div className="sp-essential_stuff">
    //                       <ul>
    //                         <li>
    //                           Availability:{" "}
    //                           <a href="javascript:void(0);">In Stock</a>
    //                         </li>
    //                         {/* <li>
    //                           Barcode:{" "}
    //                           <a href="javascript:void(0);">
    //                             {data?.getJewelry?.barcode}
    //                           </a>
    //                         </li> */}
    //                         {/* <li>
    //                           Style:{" "}
    //                           <a href="javascript:void(0);">
    //                             {data?.getJewelry?.sku}
    //                           </a>
    //                         </li> */}
    //                       </ul>
    //                     </div>
    //                     {/* <div className="sp-essential_stuff mt-4">
    //                       <h6>Available in Shapes</h6>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Round"
    //                           src="/assets/images/diamonds/round.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Pear"
    //                           src="/assets/images/diamonds/pear.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Oval"
    //                           src="/assets/images/diamonds/oval.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Princess"
    //                           src="/assets/images/diamonds/princess.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Radiant"
    //                           src="/assets/images/diamonds/radiant.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Marquise"
    //                           src="/assets/images/diamonds/marquise.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Heart"
    //                           src="/assets/images/diamonds/heart.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Emerald"
    //                           src="/assets/images/diamonds/emerald.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Asscher"
    //                           src="/assets/images/diamonds/asscher.png"
    //                         />
    //                       </a>
    //                       <a href="javascript:void(0);">
    //                         <img
    //                           className="img-diamond-shape"
    //                           data-bs-toggle="tooltip"
    //                           data-bs-placement="bottom"
    //                           title="Cushion"
    //                           src="/assets/images/diamonds/cushion.png"
    //                         />
    //                       </a>
    //                     </div> */}
    //                     <div className="quantity">
    //                       <label>Quantity</label>
    //                       <div className="cart-plus-minus">
    //                         <input
    //                           id="qtyInput"
    //                           className="cart-plus-minus-box"
    //                           value="1"
    //                           type="text"
    //                         />
    //                         <div className="dec qtybutton">
    //                           <i className="fa fa-angle-down"></i>
    //                         </div>
    //                         <div className="inc qtybutton">
    //                           <i className="fa fa-angle-up"></i>
    //                         </div>
    //                       </div>
    //                     </div>
    //                     {/* <div className="qty-btn_area">
    //                       <ul>
    //                         <li>
    //                           <AddToCart
    //                             jewelryId={data?.getJewelry?._id}
    //                             qty={1}
    //                           />
    //                         </li>
    //                         <li>
    //                           <AddToWishlist
    //                             jewelryId={data?.getJewelry?._id}
    //                             callback={() => navigate("/wishlist")}
    //                           />
    //                         </li>
    //                       </ul>
    //                     </div> */}
    //                     <div className="hiraola-tag-line">
    //                       <h6>Tags:</h6>
    //                       {data?.getProduct?.tags?.map((tag, inx) => {
    //                         return (
    //                           <>
    //                             {inx != 0 ? "," : ""}
    //                             <a href={"/?category=all&search=" + tag}>
    //                               {tag}
    //                             </a>
    //                           </>
    //                         );
    //                       })}
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default QuickView;
