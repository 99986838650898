import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import queries from "../queries";
import Stars from "./Stars";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  closeLoading,
  currency,
  getCurrCurrency,
  productSortBy,
  productStatus,
  productType,
  productTypeURL,
  setPageTitle,
  showLoading,
} from "../common";
import AddToCart, { handleAddProductToCart } from "./mini/AddToCart";
import AddToWishlist from "./mini/AddToWishlist";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";
import { FaEdit, FaTrash } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { permissions } from "../common";
import { productSubtypeURL } from "../common";
import { useRef } from "react";
import moment from "moment";
import Rating, { Size } from "./mini/Rating";
import ProductCard from "./mini/ProductCard";

function SingleProduct({
  dataSelf,
  loading: loadingSelf,
  error,
  refetch: refetchSelf,
}) {
  const params = useParams();
  const { productId } = params;
  const optionRef = useRef();

  //#region GraphQL
  const {
    loading,
    errors,
    data: dataProduct,
    refetch,
  } = useQuery(queries.products.GET_PRODUCT, {
    variables: { productId: productId },
  });
  const {
    loading: loadingRelatedProducts,
    errors: errorRelatedProducts,
    data: dataRelatedProduct,
  } = useQuery(queries.products.GET_PRODUCTS_BY_QUERY, {
    variables: {
      page: 1,
      productQuery: {
        type: dataProduct?.getProduct?.type,
      },
    },
  });
  const [addProductToCart] = useMutation(queries.member.ADD_PRODUCT_TO_CART);
  const [addReview] = useMutation(queries.products.ADD_REVIEW);
  const [removeReview] = useMutation(queries.products.REMOVE_REVIEW);
  const [removeProduct] = useMutation(queries.products.REMOVE_PRODUCT);
  //#endregion

  //#region state variables
  const [selectedOptions, setSelectedOptions] = useState({});
  const [qty, setQty] = useState(1);
  const [myReview, setMyReview] = useState("");
  const [myRating, setMyRating] = useState(0);
  const [currentImage, setCurrentImage] = useState(
    dataProduct?.getProduct?.image?.[0]
  );
  const [displayImages, setDisplayImages] = useState(
    dataProduct?.getProduct?.image
  );
  const [displayPrice, setDisplayPrice] = useState();
  const [displayOldPrice, setDisplayOldPrice] = useState();
  //#endregion

  // const {
  //   data: trendingData,
  //   loading: trendingLoading,
  //   error: trendingError,
  //   refetch: refetchTrending,
  // } = useQuery(queries.products.GET_TRENDING_PRODUCTS, {
  //   variables: {
  //     type: 2,
  //   },
  //   errorPolicy: "all",
  // });

  // const {
  //   data: getSelfData,
  //   loading: getSelfLoading,
  //   error: getSelfErr,
  // } = useQuery(queries.member.GET_SELF, {
  //   errorPolicy: "all",
  // });

  //#region useEffect
  useEffect(() => {
    if (dataProduct?.getProduct?.status == productStatus.REMOVED) {
      window.location.href = "/products";
      return;
    }
    if (dataProduct != null && dataRelatedProduct) {
      ImportMainJs();
      closeLoading();
    }
    setDisplayImages(dataProduct?.getProduct?.image);
    setDisplayPrice(dataProduct?.getProduct?.from_price?.toFixed(2));
    setDisplayOldPrice(dataProduct?.getProduct?.old_from_price?.toFixed(2));
    setPageTitle(dataProduct?.getProduct?.name);
  }, [dataProduct, dataRelatedProduct]);

  useEffect(() => {
    const observerOptions = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          // Content of the div has changed
          const option =
            mutation?.target?.parentNode?.parentNode?.parentNode?.parentNode?.querySelector(
              ".small"
            )?.textContent;
          const selectedValue = mutation?.target?.title;
          if (option?.length > 0 && selectedValue?.length > 0) {
            setSelectedOptions((prevState) => ({
              ...prevState,
              [option]: selectedValue,
            }));
          }
        }
      }
    });

    observerOptions.observe(optionRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    return () => {
      observerOptions.disconnect();
    };
  }, []);

  useEffect(() => {
    setCurrentImage(displayImages?.[0]);
  }, [displayImages]);

  useEffect(() => {
    if (Object.keys(selectedOptions).length > 0) {
      for (let i = 0; i < Object.keys(selectedOptions).length; i++) {
        const selectedOption = Object.keys(selectedOptions)[i];
        const selectedValue = selectedOptions[selectedOption];
        dataProduct?.getProduct?.options?.forEach((o) => {
          for (let j = 0; j < o?.values.length; j++) {
            const v = o?.values[j];
            if (o.title == selectedOption && v.title == selectedValue) {
              setDisplayImages(v.images?.length > 0 ? v.images : displayImages);
              setDisplayPrice(v.price);
              setDisplayOldPrice(v.old_price);
              break;
            }
          }
        });
      }
    }
  }, [selectedOptions]);
  //#endregion

  function handleAddToCartClick(product) {
    handleAddProductToCart(
      addProductToCart,
      product._id,
      qty,
      Object.keys(selectedOptions).map((selectedOption) => {
        return {
          title: selectedOption,
          value: selectedOptions[selectedOption],
        };
      }),
      refetchSelf
    );
  }

  async function handleRemoveProduct() {
    Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This product will be deleted permanently.",
      confirmButtonText: "Yes",
      showDenyButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (productId == null) throw "Something went wrong!";
          const { data } = await removeProduct({
            variables: {
              productId: productId,
            },
          });
          if (data?.removeProduct?._id != null) {
            Swal.fire({
              icon: "success",
              title: "Product removed successfully!",
            }).then(() => {
              window.location.href = "/products";
            });
          } else {
            throw "Something went wrong!";
          }
        } catch (e) {
          if (typeof e == "string") {
            Swal.fire({
              icon: "error",
              title: e,
            });
          } else {
            if (
              e?.networkError?.result?.errors?.[0]?.extensions?.code ==
              "UNAUTHENTICATED"
            ) {
              Swal.fire({
                icon: "error",
                title: "Login to continue",
                text: "You need to be logged in to remove product",
              }).then(() => {
                //we can't use useNavigate here
                window.localStorage.clear();
                window.location.href = "/login";
              });
            } else {
              Swal.fire({
                icon: "error",
                title: e.message,
              });
            }
          }
        }
      }
    });
  }

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/products">Shop</a>
            </li>
            <li className="active">{dataProduct?.getProduct?.name}</li>
          </ul>
        </div>
      </section>
      <section className="section-lg bg-white">
        <div className="container">
          <div className="row row-40">
            <div className="col-lg-6">
              <div className="slick-gallery">
                <div
                  className="slick-slider carousel-parent"
                  data-items="1"
                  data-child="#child-carousel"
                  data-htmlFor="#child-carousel"
                  data-lightgallery="group"
                >
                  <div className="item">
                    <a
                      className="link-image"
                      href={currentImage}
                      data-lightgallery="item"
                    >
                      <img src={currentImage} alt="" width="828" height="994" />
                    </a>
                  </div>
                </div>
                <div
                  className="slick-slider carousel-child"
                  id="child-carousel"
                  data-htmlFor=".carousel-parent"
                  data-arrows="true"
                  data-loop="false"
                  data-dots="false"
                  data-swipe="true"
                  data-items="5"
                  data-xs-items="5"
                  data-sm-items="5"
                  data-md-items="5"
                  data-lg-items="5"
                  data-xl-items="5"
                  data-slide-to-scroll="1"
                >
                  {displayImages?.map((image) => {
                    return (
                      <div
                        className="item"
                        onClick={() => setCurrentImage(image)}
                      >
                        <img src={image} alt="" width="828" height="994" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="single-product">
                <div className="single-product-header">
                  <div className="rating d-flex">
                    {dataProduct?.getProduct?.rating != null && (
                      <Rating rating={dataProduct?.getProduct?.rating} />
                    )}
                  </div>
                  <p className="small">
                    {dataProduct?.getProduct?.reviews?.length != null
                      ? dataProduct?.getProduct?.reviews?.length
                      : 0}{" "}
                    Review
                  </p>
                </div>
                <p className="small">SKU: {dataProduct?.getProduct?.sku}</p>
                <h3>{dataProduct?.getProduct?.name}</h3>
                <div className="single-product-prices">
                  <div className="single-product-price-old">
                    {getCurrCurrency()}
                    {displayOldPrice}
                  </div>
                  <div className="single-product-price">
                    {getCurrCurrency()}
                    {displayPrice}
                  </div>
                </div>
                <p className="single-product-text big">
                  <ReactMarkdown
                    children={dataProduct?.getProduct?.short_description}
                  />
                </p>
                <div className="single-product-select" ref={optionRef}>
                  {dataProduct?.getProduct?.options?.map((option) => {
                    return (
                      <div className="mt-2">
                        <p className="small option-title">{option?.title}</p>
                        <select
                          data-placeholder="Choose an option"
                          value={
                            dataProduct?.getProduct?.options?.values?.[1]?.title
                          }
                          onChange={(e) => {
                            console.log(e.target.value);
                          }}
                        >
                          {option?.values?.map((value) => {
                            return (
                              <option value={value.title}>{value.title}</option>
                            );
                          })}
                        </select>
                      </div>
                    );
                  })}
                </div>
                {/* <div className="single-product-description">
                  <div className="single-product-description-title">Clear</div>
                  <div className="single-product-description-text">
                    But I must explain to you how all this mistaken idea of
                    denouncing pleasure and praising pain was born and I will
                    give you a complete account of the system,.
                  </div>
                </div> */}
                <div className="single-product-footer">
                  <div className="single-product-footer-group">
                    <div className="qty-container">
                      <button
                        className="qty-stepper down"
                        onClick={(e) => setQty(qty <= 1 ? 1 : qty - 1)}
                      >
                        -
                      </button>
                      <span className="qty-span">{qty}</span>
                      <button
                        className="qty-stepper up"
                        onClick={(e) => setQty(qty + 1)}
                      >
                        +
                      </button>
                    </div>
                    <a
                      className="btn btn-primary"
                      href="javascript:void(0);"
                      onClick={(e) => {
                        handleAddToCartClick(dataProduct?.getProduct);
                      }}
                    >
                      Add to Cart
                    </a>
                  </div>
                </div>
                {dataProduct?.getProduct?.tags?.length > 0 && (
                  <div className="single-product-tags">
                    <div className="single-product-tags-title">Tags:</div>
                    {dataProduct?.getProduct?.tags?.map((tag) => {
                      return (
                        <a
                          className="single-product-tag"
                          href={`/products?search=${tag}`}
                        >
                          {tag}
                        </a>
                      );
                    })}
                  </div>
                )}

                {dataSelf?.getSelf?.type?.role_access?.includes(
                  permissions.MODIFY_PRODUCTS
                ) && (
                  <div className="single-product-footer">
                    <div className="single-product-footer-group">
                      <a
                        className="btn btn-primary"
                        href={`/add-product/${dataProduct?.getProduct?._id}`}
                      >
                        Update
                      </a>
                      <a
                        className="btn btn-primary"
                        href="javascript:void(0);"
                        onClick={(e) => {
                          handleRemoveProduct();
                        }}
                      >
                        Remove
                      </a>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="tabs-custom tabs-horizontal tabs-classic" id="tabs-1">
            <ul className="nav nav-tabs row">
              <li className="nav-item col-sm-12 col-md-2" role="presentation">
                <a className="nav-link" href="#tabs-1-0" data-toggle="tab">
                  Description
                </a>
              </li>
              <li className="nav-item col-sm-12 col-md-2" role="presentation">
                <a className="nav-link" href="#tabs-1-1" data-toggle="tab">
                  FAQs
                </a>
              </li>
              <li className="nav-item col-sm-12 col-md-3" role="presentation">
                <a className="nav-link" href="#tabs-1-2" data-toggle="tab">
                  Additional information
                </a>
              </li>
              <li className="nav-item col-sm-12 col-md-2" role="presentation">
                <a className="nav-link" href="#tabs-1-3" data-toggle="tab">
                  Reviews (
                  {dataProduct?.getProduct?.reviews?.length != null
                    ? dataProduct?.getProduct?.reviews?.length
                    : 0}
                  )
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade" id="tabs-1-0">
                <p>
                  <ReactMarkdown
                    children={dataProduct?.getProduct?.short_description}
                  />
                </p>
              </div>
              <div className="tab-pane fade" id="tabs-1-1">
                {dataProduct?.getProduct?.qna?.length > 0 &&
                  dataProduct?.getProduct?.qna?.map((q) => {
                    return (
                      <div className="row row-30 flex-md-row-reverse">
                        <div className="col-md-12" hidden={true}>
                          <img
                            className="rounded"
                            src="/images/single-product-01-560x431.jpg"
                            alt=""
                            width="560"
                            height="431"
                          />
                        </div>
                        <div className="col-md-12">
                          <h3>{q.question}</h3>
                          <p>{q.answer}</p>
                        </div>
                      </div>
                    );
                  })}
                {dataProduct?.getProduct?.qna?.length === 0 && (
                  <div className="big">
                    There are no FAQs for this product. Keep in touch to know
                    more.
                  </div>
                )}
              </div>
              <div className="tab-pane fade" id="tabs-1-2">
                {dataProduct?.getProduct?.additional_info?.length === 0 && (
                  <div className="big">
                    There are no more information for this product. Keep in
                    touch to know more.
                  </div>
                )}
                {dataProduct?.getProduct?.additional_info?.length > 0 && (
                  <div>
                    <h4>Additional Information</h4>
                    <ul className="additional-list">
                      {dataProduct?.getProduct?.additional_info?.map((info) => {
                        return (
                          <li className="additional-list-item">
                            <div className="additional-list-title">
                              {info.title} -{" "}
                            </div>
                            <div className="additional-list-text">
                              {info.value}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
              <div className="tab-pane fade" id="tabs-1-3">
                <h4>Reviews</h4>
                {dataProduct?.getProduct?.reviews?.map((review) => {
                  return (
                    <div className="review">
                      <figure className="review-figure">
                        <a className="link-image rounded-circle" href="#">
                          <img
                            src="/images/logo.svg"
                            alt=""
                            width="130"
                            height="130"
                          />
                        </a>
                      </figure>
                      <div className="review-body">
                        <div className="review-rating">
                          <div className="rating">
                            {review.rating && <Rating rating={review.rating} />}
                          </div>
                        </div>
                        <div className="review-meta">
                          <ul className="meta-list">
                            <li className="meta-list-item">
                              <time dateTime="2021">
                                Published on{" "}
                                {moment(review.date_added).format(
                                  "MMMM D, YYYY"
                                )}
                              </time>
                            </li>
                            <li className="meta-list-item">
                              <div className="post-author">
                                <div className="post-author-name">
                                  <span>by</span>
                                  <a href="javascript:void(0);">
                                    {review.member.fname} {review.member.lname}
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="review-text">{review.review}</div>
                      </div>
                      {dataSelf?.getSelf?._id == review.member_id && (
                        <div>
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              Swal.fire({
                                icon: "question",
                                title: "Are you sure?",
                                text: "Your review will be deleted permanently.",
                                confirmButtonText: "Yes",
                                showDenyButton: true,
                              }).then(async (result) => {
                                if (result.isConfirmed) {
                                  try {
                                    const { data } = await removeReview({
                                      variables: {
                                        reviewId: review._id,
                                      },
                                    });
                                    if (data?.removeReview != null) {
                                      Swal.fire({
                                        icon: "success",
                                        title: "Review deleted successfully",
                                      }).then(() => {
                                        window.location.reload();
                                      });
                                    } else {
                                      throw new Error(
                                        "Failed to delete review"
                                      );
                                    }
                                  } catch (error) {
                                    Swal.fire({
                                      icon: "error",
                                      title: error.message,
                                    });
                                  }
                                }
                              });
                            }}
                          >
                            Delete
                          </a>
                        </div>
                      )}
                    </div>
                  );
                })}
                {dataProduct?.getProduct?.reviews?.find(
                  (review) => review.member_id == dataSelf?.getSelf?._id
                ) == null && (
                  <>
                    <h3>Leave a Review</h3>
                    <form
                      className="rd-mailform text-left mt-4"
                      data-form-output="form-output-global"
                      data-form-type="contact"
                      method="post"
                      action="bat/rd-mailform.php"
                    >
                      <p>
                        Your email address will not be published. Required
                        fields are marked with *
                      </p>
                      <div className="row row-30">
                        <div className="col-12">
                          <div className="form-wrap group-sm d-flex align-items-center">
                            <div>Your Rating*</div>
                            <div className="rating">
                              <Rating
                                rating={myRating}
                                editable={true}
                                size={Size.LARGE}
                                onRatingUpdate={setMyRating}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-wrap">
                            <label
                              className="form-label form-label-outside"
                              htmlFor="comment-message"
                            >
                              Your Comment*
                            </label>
                            <textarea
                              className="form-input"
                              id="comment-message"
                              name="message"
                              value={myReview}
                              onChange={(e) => setMyReview(e.target.value)}
                              data-constraints="@Required"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="form-wrap">
                        <label className="shop-checkbox-wrap">
                          <span className="shop-checkbox-label">
                            Your data will not be transferred to third parties.
                          </span>
                        </label>
                      </div>
                      <div className="form-button">
                        <button
                          className="btn btn-primary"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (myRating == null || myRating == 0) {
                              Swal.fire({
                                icon: "error",
                                title: "Please select rating",
                              });
                              return;
                            } else if (myReview?.length == 0) {
                              Swal.fire({
                                icon: "error",
                                title: "Please write a comment",
                              });
                              return;
                            }
                            try {
                              const { data } = await addReview({
                                variables: {
                                  productId: productId,
                                  reviewArgs: {
                                    rating: myRating,
                                    review: myReview,
                                  },
                                },
                              });
                              setMyReview("");
                              setMyRating(0);
                              if (data?.addReview) {
                                Swal.fire({
                                  icon: "success",
                                  title: "Review added successfully",
                                }).then(() => {
                                  window.location.reload();
                                });
                              } else {
                                throw new Error("Failed to add review");
                              }
                            } catch (error) {
                              Swal.fire({
                                icon: "error",
                                title: error.message,
                              });
                            }
                          }}
                        >
                          Leave a Review
                        </button>
                      </div>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container">
          <h3>Related Products</h3>
          <div
            className="owl-carousel owl-theme owl-carousel-style-2"
            data-items="1"
            data-xl-margin="40"
            data-xs-items="2"
            data-sm-items="2"
            data-md-items="3"
            data-lg-items="3"
            data-xl-items="4"
            data-owl='{"dots":true,"nav":false,"autoplayTimeout":3500,"margin":30,"stagePadding":0}'
          >
            {dataRelatedProduct?.getProductsByQuery?.map((product) => {
              return <ProductCard product={product} refetch={refetchSelf} />;
            })}
          </div>
        </div>
      </section>
    </>
  );
}

{
  /* <form
className="rd-mailform text-left mt-4"
data-form-output="form-output-global"
data-form-type="contact"
method="post"
action="bat/rd-mailform.php"
>
<div className="form-wrap">
  <label
    className="form-label form-label-outside"
    htmlhtmlFor="contact-email"
  >
    Rating:
  </label>
  <Stars type="rate" callbackSetRating={setRating}></Stars>
</div>
<div className="form-wrap">
  <label
    className="form-label form-label-outside"
    htmlhtmlFor="contact-message"
  >
    Review
  </label>
  <textarea
    className="form-input"
    id="review-message"
    name="message"
  ></textarea>
</div>
<div className="form-button">
  <button
    className="btn btn-primary"
    // disabled={rating == 0}
    // onClick={(e) => {
    //   handleAddReview(
    //     e,
    //     document.getElementById("review-message").value
    //   );
    // }}
  >
    Post Review
  </button>
</div>
</form> */

  {
    /* <div className="col-lg-8">
<div className="pr-lg-5">
  <div className="row row-10 gutters-10" data-lightgallery="group">
    <div className="col-xs-6">
      <a
        className="product-gallery"
        data-lightgallery="item"
        href="/images/shop/product-single-01-original.png"
      >
        <img
          src={oneProduct?.image?.[0]}
          alt=""
          width="365"
          height="365"
        />
      </a>
    </div>
    <div className="col-xs-6">
      <a
        className="product-gallery"
        data-lightgallery="item"
        href="/images/shop/product-single-02-original.png"
      >
        <img
          src="/images/shop/product-single-02-365x365.png"
          alt=""
          width="365"
          height="365"
        />
      </a>
    </div>
    <div className="col-xs-6">
      <a
        className="product-gallery"
        data-lightgallery="item"
        href="/images/shop/product-single-03-original.png"
      >
        <img
          src="/images/shop/product-single-03-365x365.png"
          alt=""
          width="365"
          height="365"
        />
      </a>
    </div>
    <div className="col-xs-6">
      <a
        className="product-gallery"
        data-lightgallery="item"
        href="/images/shop/product-single-04-original.png"
      >
        <img
          src="/images/shop/product-single-04-365x365.png"
          alt=""
          width="365"
          height="365"
        />
      </a>
    </div>
    <div className="col-xs-6">
      <a
        className="product-gallery"
        data-lightgallery="item"
        href="/images/shop/product-single-05-original.png"
      >
        <img
          src="/images/shop/product-single-05-365x365.png"
          alt=""
          width="365"
          height="365"
        />
      </a>
    </div>
  </div>
</div>
</div> */
  }
}
export default SingleProduct;
