import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import queries from "../queries";
import {
  calculateSubtotal,
  calculateTotal,
  getCurrCurrency,
  isUserLoggedIn,
  orderStatus,
  orderStatusName,
  paymentStatusColor,
  permissions,
  productStatus,
  setPageTitle,
} from "../common";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./wishlist.css";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";
import moment from "moment";
import ReactDOMServer from "react-dom/server";

function ManageOrders({ dataSelf }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const {
    data: dataOrders,
    loading: orderLoading,
    error: orderError,
  } = useQuery(queries.member.GET_PURCHASED_ORDERS, {
    variables: { page: page },
  });
  const [updateOrderStatus] = useMutation(queries.general.UPDATE_ORDER_STATUS);
  const { data, loading, error } = useQuery(queries.member.GET_SELF, {
    errorPolicy: "all",
    onError: () => {
      window.location.href = "/";
    },
    onCompleted: (data) => {
      if (
        data?.getSelf?.type?.role_access?.includes(permissions.MANAGE_ORDERS) ==
        false
      ) {
        window.location.href = "/";
      }
    },
  });
  setPageTitle("Manage Orders");

  async function handleOrderStatusChange(
    newStatus,
    purchasedItem,
    trackingUrl
  ) {
    try {
      await updateOrderStatus({
        variables: {
          newStatus: parseInt(newStatus),
          purchasedId: purchasedItem?._id,
          trackingUrl: trackingUrl,
        },
      });
      window.location.reload();
    } catch (error) {
      if (typeof error == "string") {
        error = {
          message: error,
        };
      }
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  }

  function handleUpdateStatusClick(purchasedItem) {
    function UpdateStatusModal(props) {
      const [newStatus, setNewStatus] = useState(purchasedItem?.status);
      const [trackingUrl, setTrackingUrl] = useState("");
      return (
        <div>
          <div className="mt-3">
            <label
              className="form-label form-label-outside"
              htmlFor="from-price"
            >
              Select new order status
            </label>
            <select
              id="select-order-status"
              class="form-input"
              value={newStatus}
              onChange={(e) => console.log(e.target.value)}
            >
              {Object.keys(orderStatus).map((orderStatusId) => (
                <option value={orderStatusId}>
                  {orderStatus[orderStatusId]}
                </option>
              ))}
            </select>
          </div>
          {/* {newStatus == orderStatusName.Shipped && (
            <div className="mt-3">
              <label
                className="form-label form-label-outside"
                htmlFor="from-price"
              >
                Tracking URL (e.g. https://www.usps.com/tracking?id=123)
              </label>
              <input
                className="form-input"
                id="tracking-url"
                type="text"
                name="tracking-url"
                value={trackingUrl}
                onChange={(e) => {
                  setTrackingUrl(e.target.value);
                }}
              />
            </div>
          )} */}
        </div>
      );
    }

    const html = ReactDOMServer.renderToString(<UpdateStatusModal />);
    Swal.fire({
      title: "Update Status",
      html: html,
      confirmButtonText: "OK",
      focusConfirm: false,
      preConfirm: () => {
        const dropdown = document.getElementById("select-order-status");
        const newStatus = dropdown.options[dropdown.selectedIndex].value;
        if (newStatus == orderStatusName.Shipped) {
          Swal.fire({
            title: "Provide Tracking URL",
            input: "text",
            inputPlaceholder: "e.g. https://www.usps.com/tracking?id=123",
            inputValidator: (value) => {
              if (!value) {
                return "Tracking URL is required to update order status to Shipped";
              }
            },
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              const trackingUrl = result.value;
              handleOrderStatusChange(newStatus, purchasedItem, trackingUrl);
            }
          });
        } else {
          // update status
          handleOrderStatusChange(newStatus, purchasedItem);
        }
      },
      showCancelButton: true,
    }).then((result) => {});
  }

  useEffect(() => {
    if (dataOrders?.getPurchasedOrders != null) {
      ImportMainJs();
    }
  }, [dataOrders]);

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">Manage Orders</li>
          </ul>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container">
          <h2 className="text-center">My Orders</h2>
          {dataSelf?.getSelf?.purchased?.length == 0 && (
            <div className="text-center">
              <h5 className="mt-5">Looks like there are no orders till now.</h5>
            </div>
          )}
          {dataOrders?.getPurchasedOrders?.map((purchasedItem) => {
            return (
              <div className="row order-item">
                <div className="col-12 order-item-title">
                  <div className="row">
                    <div className="col-sm-8">
                      <h4>#{purchasedItem?._id}</h4>
                      <span
                        className="order-item-status"
                        style={{
                          backgroundColor:
                            paymentStatusColor[purchasedItem?.status],
                        }}
                      >
                        {orderStatus[purchasedItem?.status]}
                      </span>
                      {" " +
                        moment(purchasedItem?.date).format(
                          "Do MMMM, YYYY hh:MM a"
                        )}
                    </div>
                    <div className="col-md-4 text-right text-muted">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => handleUpdateStatusClick(purchasedItem)}
                      >
                        Update Status
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-12 p-0">
                  <div className="cart-main">
                    {purchasedItem?.products?.map((product) => {
                      return (
                        <div className="cart-item py-1 px-4 border-bottom-0">
                          <figure className="cart-item-col cart-item-col-figure">
                            <a
                              className="link-image rounded"
                              href={`/product/${product?.product_id}`}
                            >
                              <img
                                src={product?.product?.image?.[0]}
                                alt=""
                                width="260"
                                height="312"
                              />
                            </a>
                          </figure>
                          <div className="cart-item-col cart-item-col-content">
                            <div className="cart-item-title">
                              <a href={`/product/${product?.product_id}`}>
                                {product?.product?.name}{" "}
                                {product?.options?.length > 0 ? " - " : ""}
                                {product?.options?.map((o) => o.value + " ")}
                              </a>
                            </div>
                          </div>
                          <div className="cart-item-col-price">
                            {getCurrCurrency()}
                            {product?.price} x {product?.qty} {"= "}
                            <span className="cart-subtitle font-weight-bold">
                              {getCurrCurrency()}
                              {(product?.price * product?.qty).toFixed(2)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12 order-item-footer">
                  <div className="row">
                    <div className="col">
                      {purchasedItem?.member?.fname +
                        " " +
                        purchasedItem?.member?.lname +
                        " (" +
                        purchasedItem?.member?.email +
                        ")"}
                      <br />
                      {purchasedItem?.address?.line1 +
                        ", " +
                        purchasedItem?.address?.state +
                        ", " +
                        purchasedItem?.address?.postal_code}
                      <br />
                      {purchasedItem?.tracking && (
                        <a target="_blank" href={purchasedItem?.tracking}>
                          Track Order
                        </a>
                      )}
                    </div>
                    <div className="mr-4">
                      {purchasedItem?.taxAmount && (
                        <div className="row mt-0 mr-0">
                          <span className="col">Taxes:&nbsp;</span>
                          <span className="font-weight-bold cart-subtitle">
                            {getCurrCurrency()}
                            {purchasedItem?.taxAmount}
                          </span>
                        </div>
                      )}
                      {purchasedItem?.shippingAmount && (
                        <div className="row mt-0 mr-0">
                          <span className="col">Shipping:&nbsp;</span>
                          <span className="font-weight-bold cart-subtitle">
                            {getCurrCurrency()}
                            {purchasedItem?.shippingAmount}
                          </span>
                        </div>
                      )}
                      <div className="row mt-0 mr-0">
                        <span className="col">Total:&nbsp;</span>
                        <span className="font-weight-bold cart-subtitle">
                          {getCurrCurrency()}
                          {purchasedItem?.amount}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default ManageOrders;
