/* eslint-disable no-unused-vars */
import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  productStatus,
  productType,
  permissions,
  productTypeURL,
  productTypeDisplayNames,
  productSubtypeDisplayNames,
  setPageTitle,
} from "../common.js";
import queries from "../queries";
// import GemstoneInfo from "./extra/GemstoneInfo";
import ImageUploader from "./extra/ImageUploader";
import ImageUploaderOptions from "./extra/ImageUploaderOptions";
import { ImportMainJs } from "../helper/ImportScript";
import ReactMarkdown from "react-markdown";
import { productSubtype } from "../common.js";

function AddProduct(props) {
  const params = useParams();
  const productId = params.productId;
  const { data: dataProduct } = useQuery(queries.products.GET_PRODUCT, {
    variables: {
      productId: productId,
    },
  });

  function isEdit() {
    return productId != null;
  }

  useEffect(() => {
    ImportMainJs();
  }, []);
  setPageTitle("Add Product");

  const [type, setType] = useState(productType.KRATOM);
  const [subtype, setSubtype] = useState(
    productSubtype.KRATOM.LIQUID_EXTRACT_SHOTS
  );
  const [sku, setSKU] = useState("");
  const [status, setStatus] = useState(productStatus.ACTIVE);
  const [name, setName] = useState("");
  const [images, setImages] = useState([]);
  const [barcode, setBarcode] = useState("");
  const [stock, setStock] = useState(1);
  const [old_price, setOldPrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [rating, setRating] = useState(0);
  const [popularity, setPopularity] = useState(0);
  const [additionalInfo, setAdditionalInfo] = useState([
    { title: "", value: "" },
  ]);

  const [options, setOptions] = useState([
    {
      title: "",
      values: [{ title: "", old_price: 0, price: 0, images: [], stock: 0 }],
    },
  ]);

  const [qna, setQna] = useState([{ question: "", answer: "" }]);
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [addProduct] = useMutation(queries.products.ADD_PRODUCT);
  const [updateProduct] = useMutation(queries.products.UPDATE_PRODUCT);

  useEffect(() => {
    if (dataProduct?.getProduct != null) {
      const product = dataProduct?.getProduct;
      const options = product?.options?.map((option) => {
        delete option.__typename;
        option?.values.map((value) => delete value.__typename);
        return option;
      });
      const additional_info = product.additional_info?.map((info) => {
        delete info.__typename;
        return info;
      });
      setType(product.type);
      setSubtype(product.subtype);
      setSKU(product.sku);
      setStatus(product.status);
      setName(product.name);
      setImages(product.image);
      setBarcode(product.barcode);
      setDiscount(product.discount);
      setPrice(product.from_price);
      setDescription(product.short_description);
      setOldPrice(product.old_from_price);
      setOptions(options);
      setAdditionalInfo(additional_info);
      setQna(
        product.qna?.map((qna) => {
          delete qna.__typename;
          return qna;
        })
      );
      setTags(product.tags);
      setStock(product.stock);
      setPopularity(product.popularity);
    }
  }, [dataProduct]);

  const navigate = useNavigate();
  const {
    data: dataGetSelf,
    loading,
    error,
  } = useQuery(queries.member.GET_SELF, {
    errorPolicy: "all",
    onError: () => {
      navigate("/");
    },
    onCompleted: (data) => {
      if (
        data?.getSelf?.type?.role_access?.includes(
          permissions.MODIFY_PRODUCTS
        ) === false
      ) {
        navigate("/");
      }
    },
  });

  useEffect(() => {
    if (
      type &&
      !Object.keys(productSubtypeDisplayNames[productTypeURL[type]]).includes(
        subtype + ""
      )
    ) {
      setSubtype(
        parseInt(
          Object.keys(productSubtypeDisplayNames[productTypeURL[type]])?.[0]
        )
      );
    }
  }, [subtype, type]);

  function clearForm() {
    setName("");
    setSKU("");
    setBarcode("");
    setType(productType.KRATOM);
    setSubtype(productSubtype.KRATOM.LIQUID_EXTRACT_SHOTS);
    setStatus(productStatus.ACTIVE);
    setDescription("");
    setOldPrice(0);
    setDiscount(0);
    setImages([]);
    setTags([]);
    setQna([]);
    setOptions([]);
    setPopularity(0);
    setStock(0);
    setRating(0);
  }

  const addAdditionalInfo = () => {
    setAdditionalInfo([...additionalInfo, { title: "", value: "" }]);
  };
  const addQna = () => {
    setQna([...qna, { question: "", answer: "" }]);
  };
  const addOptions = () => {
    setOptions([
      ...options,
      {
        title: "",
        values: [{ title: "", old_price: 0, price: 0, images: [], stock: 0 }],
      },
    ]);
  };
  const addOptionValues = (index) => {
    let temp = [
      ...options[index]["values"],
      { title: "", old_price: 0, price: 0, images: [], stock: 0 },
    ];
    let midObj = { ...options[index] };
    midObj["values"] = temp;
    let finalObj = [...options];
    finalObj[index] = midObj;
    setOptions(finalObj);
  };

  const handleChangeAdditionalInfo = (text, index, type) => {
    let temp = [...additionalInfo];
    temp[index][type] = text;
    setAdditionalInfo(temp);
  };

  const removeAdditionalInfoInstance = (index) => {
    let temp = [...additionalInfo];
    temp.splice(index, 1);
    setAdditionalInfo(temp);
  };

  const handleChangeQna = (text, index, type) => {
    let temp = [...qna];
    temp[index][type] = text;
    setQna(temp);
  };
  const removeQnaInstance = (index) => {
    let temp = [...qna];
    temp.splice(index, 1);
    setQna(temp);
  };

  const handleOptionTitleChange = (text, index) => {
    let temp = [...options];
    temp[index]["title"] = text;
    setOptions(temp);
  };

  const handleOptionValuesChange = (text, outerIndex, innerIndex, type) => {
    let temp = [...options[outerIndex]["values"]];
    if (type === "old_price") {
      temp[innerIndex][type] = parseFloat(text);
    } else if (type === "price") {
      temp[innerIndex][type] = parseFloat(text);
    } else if (type === "stock") {
      temp[innerIndex][type] = parseFloat(text);
    } else {
      temp[innerIndex][type] = text;
    }

    let midObj = { ...options[outerIndex] };
    midObj["values"] = temp;
    let finalObj = [...options];
    finalObj[outerIndex] = midObj;
    setOptions(finalObj);
  };

  const removeOption = (index) => {
    let temp = [...options];
    temp.splice(index, 1);
    setOptions(temp);
  };

  const removeOptionValue = (outerIndex, innerIndex) => {
    let temp = [...options[outerIndex]["values"]];
    temp.splice(innerIndex, 1);
    let midObj = { ...options[outerIndex] };
    midObj["values"] = temp;
    let finalObj = [...options];
    finalObj[outerIndex] = midObj;
    setOptions(finalObj);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (isEdit()) {
        const { data } = await updateProduct({
          variables: {
            productId: productId,
            updateProductArgs: {
              type: type,
              subtype: subtype,
              sku,
              name,
              image: images,
              barcode,
              old_from_price: parseFloat(old_price),
              from_price: parseFloat(price),
              discount: parseFloat(discount),
              options,
              rating: 0,
              short_description: description,
              additional_info: additionalInfo,
              qna,
              tags,
              stock,
              popularity,
            },
          },
        });
        Swal.fire({
          icon: "success",
          title: "Product updated",
        }).then(() => {
          if (data?.updateProduct?._id) {
            window.location.href = "/product/" + data?.updateProduct?._id;
          } else {
            window.location.href = "/add-product";
          }
        });
      } else {
        const { data } = await addProduct({
          variables: {
            productArgs: {
              type: type,
              subtype: subtype,
              sku,
              name,
              image: images,
              barcode,
              old_from_price: parseFloat(old_price),
              from_price: parseFloat(price),
              discount: parseFloat(discount),
              options,
              rating: 0,
              short_description: description,
              additional_info: additionalInfo,
              qna,
              tags,
              stock,
              popularity,
            },
          },
        });
        Swal.fire({
          icon: "success",
          title: "New Product added",
        }).then(() => {
          if (data?.addProduct?._id) {
            window.location.href = "/product/" + data?.addProduct?._id;
          } else {
            window.location.href = "/add-product";
          }
        });
      }
      clearForm();
    } catch (error) {
      // eslint-disable-next-line no-ex-assign
      if (typeof error == "string") error = { message: error };
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  }

  return (
    <section className="section section-md bg-white text-center">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-11 col-lg-9 col-xl-7">
            <h3> {isEdit() ? "Update Product" : "Add Product"}</h3>
            <form className="rd-form rd-mailform">
              <div className="row row-20">
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="contact-name"
                    >
                      Name
                    </label>
                    <input
                      className="form-input"
                      id="contact-name"
                      type="text"
                      name="name"
                      value={name}
                      data-constraints="@Required"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="contact-phone"
                    >
                      SKU
                    </label>
                    <input
                      className="form-input"
                      id="contact-phone"
                      type="text"
                      name="sku"
                      value={sku}
                      onChange={(e) => {
                        setSKU(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="barcode"
                    >
                      Barcode
                    </label>
                    <input
                      className="form-input"
                      id="barcode"
                      type="text"
                      name="email"
                      value={barcode}
                      onChange={(e) => {
                        setBarcode(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="status"
                    >
                      Status
                    </label>
                    <select
                      className="nice-select select-search-category"
                      onChange={(e) => {
                        setStatus(parseInt(e.target.value));
                      }}
                      value={status}
                    >
                      {Object.keys(productStatus).map((productStatusName) => {
                        return (
                          <option value={productStatus[productStatusName]}>
                            {productStatusName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="stock"
                    >
                      Stock
                    </label>
                    <input
                      className="form-input"
                      id="stock"
                      type="number"
                      name="stock"
                      value={stock}
                      onChange={(e) => {
                        setStock(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="type"
                    >
                      Type
                    </label>
                    <select
                      className="form-input nice-select select-search-category"
                      onChange={(e) => {
                        setType(parseInt(e.target.value));
                      }}
                      value={type}
                    >
                      {Object.keys(productTypeDisplayNames).map(
                        (productTypeDisplayNameId) => {
                          return (
                            <option value={productTypeDisplayNameId}>
                              {
                                productTypeDisplayNames[
                                  productTypeDisplayNameId
                                ]
                              }
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="type"
                    >
                      Sub-Type
                    </label>
                    <select
                      className="form-input select-search-category"
                      onChange={(e) => {
                        setSubtype(parseInt(e.target.value));
                      }}
                      value={subtype}
                    >
                      {Object.keys(
                        productSubtypeDisplayNames[productTypeURL[type]]
                      ).map((productSubtypeNameId) => {
                        return (
                          <option value={productSubtypeNameId}>
                            {
                              productSubtypeDisplayNames[productTypeURL[type]][
                                productSubtypeNameId
                              ]
                            }
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label
                      className="form-label form-label-outside"
                      htmlFor="from-old-price"
                    >
                      Old Price ($)
                    </label>
                    <input
                      className="form-input"
                      id="from-old-price"
                      type="number"
                      name="old_price"
                      min={0}
                      value={old_price}
                      onChange={(e) => {
                        const old_price = parseFloat(e.target.value);
                        const discount =
                          ((old_price - price) * 100) / old_price;
                        setDiscount(discount);
                        setOldPrice(old_price);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div>
                    <label
                      className="form-label form-label-outside"
                      htmlFor="old-price"
                    >
                      New Price ($)
                    </label>
                    <input
                      className="form-input"
                      id="old-price"
                      type="number"
                      name="old-price"
                      min={0}
                      value={price}
                      onChange={(e) => {
                        const price = parseFloat(e.target.value);
                        const discount =
                          ((old_price - price) * 100) / old_price;
                        setDiscount(discount);
                        setPrice(price);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <label
                      className="form-label form-label-outside"
                      htmlFor="discount"
                    >
                      Discount (%)
                    </label>
                    <input
                      className="form-input"
                      id="discount"
                      type="number"
                      name="discount"
                      min={0}
                      value={discount}
                      onChange={(e) => {
                        const discount = parseFloat(e.target.value);
                        const price = (old_price * (100 - discount)) / 100;
                        setDiscount(discount);
                        setPrice(price);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label>Images</label>
                  <ImageUploader
                    attachImageURLsToProduct={setImages}
                    images={images}
                  />
                </div>
                <div className="col-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="contact-message"
                    >
                      Description
                    </label>

                    <textarea
                      className="form-input"
                      id="contact-message"
                      name="message"
                      data-constraints="@Required"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    ></textarea>

                    <ReactMarkdown children={description} />
                  </div>
                </div>
                {/* <div className="col-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="stock"
                    >
                      Stock
                    </label>
                    <input
                      className="form-input"
                      id="stock"
                      type="number"
                      name="stock"
                      value={stock}
                      onChange={(e) => {
                        setStock(parseInt(e.target.value));
                      }}
                    />
                  </div>
                </div> */}
                {/*//////////// ADDITIONAL INFO ///////////*/}
                <h4 className="col-md-12">Additional Info</h4>
                {additionalInfo.map((element, index) => {
                  return (
                    <>
                      <div className="col-md-1 mt-5">
                        <button
                          className="navbar-cart-remove"
                          onClick={(e) => {
                            e.preventDefault();
                            removeAdditionalInfoInstance(index);
                          }}
                        >
                          <svg
                            className="icon-svg"
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5 0H11V2H16V4H0V2H5V0Z"></path>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2 20H14V6H2V20ZM12 18H4V8H12V18Z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="col-md-5">
                        <div>
                          <label
                            className="form-label form-label-outside"
                            for={"addInfoTitle_" + index}
                          >
                            Title
                          </label>
                          <input
                            className="form-input"
                            id={"addInfoTitle_" + index}
                            type="text"
                            name="email"
                            data-constraints="@Required"
                            value={additionalInfo[index]["title"]}
                            onChange={(e) => {
                              handleChangeAdditionalInfo(
                                e.target.value,
                                index,
                                "title"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div>
                          <label
                            className="form-label form-label-outside"
                            for={"addInfoValue_" + index}
                          >
                            Value
                          </label>
                          <input
                            className="form-input"
                            id={"addInfoValue_" + index}
                            type="text"
                            name="email"
                            value={additionalInfo[index]["value"]}
                            data-constraints="@Required"
                            onChange={(e) => {
                              handleChangeAdditionalInfo(
                                e.target.value,
                                index,
                                "value"
                              );
                            }}
                          />
                        </div>
                      </div>
                    </>
                  );
                })}
                <div className="col-md-12">
                  <div>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addAdditionalInfo();
                      }}
                    >
                      Add Additional Info
                    </button>
                  </div>
                </div>
                {/*///////////// OPTIONS //////////////*/}
                <h4 className="col-md-12">Options</h4>
                {options.map((element, optionIndex) => {
                  return (
                    <>
                      <div className="col-md-1 mt-5">
                        <button
                          className="navbar-cart-remove"
                          onClick={(e) => {
                            e.preventDefault();
                            removeOption(optionIndex);
                          }}
                        >
                          <svg
                            className="icon-svg"
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5 0H11V2H16V4H0V2H5V0Z"></path>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2 20H14V6H2V20ZM12 18H4V8H12V18Z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="col-md-5">
                        <div>
                          <label
                            className="form-label form-label-outside"
                            for={"optionTitle_" + optionIndex}
                          >
                            Title
                          </label>
                          <input
                            className="form-input"
                            id={"optionTitle_" + optionIndex}
                            type="text"
                            name="optionTitle"
                            data-constraints="@Required"
                            value={options[optionIndex]["title"]}
                            onChange={(e) => {
                              handleOptionTitleChange(
                                e.target.value,
                                optionIndex
                              );
                            }}
                          />
                        </div>
                      </div>

                      {options[optionIndex]["values"].map(
                        (innerElement, valueIndex) => {
                          return (
                            <>
                              {valueIndex > 0 ? (
                                <div className="col-md-6"></div>
                              ) : (
                                ""
                              )}
                              <div className="col-md-6">
                                <div>
                                  <label
                                    className="form-label form-label-outside"
                                    for={"addInfoValue_" + optionIndex}
                                  >
                                    Value title
                                  </label>
                                  <input
                                    className="form-input"
                                    id={"addInfoValue_" + optionIndex}
                                    type="email"
                                    name="email"
                                    data-constraints="@Required"
                                    value={
                                      options[optionIndex]["values"][
                                        valueIndex
                                      ]["title"]
                                    }
                                    onChange={(e) => {
                                      handleOptionValuesChange(
                                        e.target.value,
                                        optionIndex,
                                        valueIndex,
                                        "title"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div>
                                  <label
                                    className="form-label form-label-outside"
                                    for={"addInfoValue_" + optionIndex}
                                  >
                                    Old Price ($)
                                  </label>
                                  <input
                                    className="form-input"
                                    id={"addInfoValue_" + optionIndex}
                                    type="number"
                                    name="email"
                                    min={0}
                                    value={
                                      options[optionIndex]["values"][
                                        valueIndex
                                      ]["old_price"]
                                    }
                                    onChange={(e) => {
                                      handleOptionValuesChange(
                                        e.target.value,
                                        optionIndex,
                                        valueIndex,
                                        "old_price"
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div>
                                  <label
                                    className="form-label form-label-outside"
                                    for={"addInfoValue_" + optionIndex}
                                  >
                                    New Price ($)
                                  </label>
                                  <input
                                    className="form-input"
                                    id={"addInfoValue_" + optionIndex}
                                    type="number"
                                    name="price"
                                    min={0}
                                    value={
                                      options[optionIndex]["values"][
                                        valueIndex
                                      ]["price"]
                                    }
                                    onChange={(e) => {
                                      handleOptionValuesChange(
                                        e.target.value,
                                        optionIndex,
                                        valueIndex,
                                        "price"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6"></div>
                              <div className="col-md-6">
                                <div>
                                  <label
                                    className="form-label form-label-outside"
                                    for={"addInfoValue_" + optionIndex}
                                  >
                                    Stock
                                  </label>
                                  <input
                                    className="form-input"
                                    id={"addInfoValue_" + optionIndex}
                                    type="number"
                                    name="stock"
                                    min={0}
                                    value={
                                      options[optionIndex]["values"][
                                        valueIndex
                                      ]["stock"]
                                    }
                                    onChange={(e) => {
                                      handleOptionValuesChange(
                                        e.target.value,
                                        optionIndex,
                                        valueIndex,
                                        "stock"
                                      );
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6"></div>
                              <div className="col-md-6 mt-1 text-center">
                                <button
                                  className="navbar-cart-remove"
                                  onClick={() => {
                                    removeOptionValue(optionIndex, valueIndex);
                                  }}
                                >
                                  <svg
                                    className="icon-svg"
                                    width="16"
                                    height="20"
                                    viewBox="0 0 16 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M5 0H11V2H16V4H0V2H5V0Z"></path>
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M2 20H14V6H2V20ZM12 18H4V8H12V18Z"
                                    ></path>
                                  </svg>
                                </button>
                              </div>

                              <div className="col-md-6"></div>
                              <div className="col-md-6" hidden={false}>
                                <div>
                                  <label
                                    className="form-label form-label-outside"
                                    for={"addInfoValue_" + optionIndex}
                                  >
                                    Images
                                  </label>
                                  <ImageUploaderOptions
                                    attachImageURLsToOptions={setOptions}
                                    options={options}
                                    optionIndex={optionIndex}
                                    valueIndex={valueIndex}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                              <hr width="50%"></hr>
                            </>
                          );
                        }
                      )}
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div>
                          <button
                            className="btn btn-block btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              addOptionValues(optionIndex);
                            }}
                          >
                            Add Values
                          </button>
                        </div>
                      </div>
                      <hr className="col-md-12"></hr>
                    </>
                  );
                })}

                <div className="col-md-12">
                  <div>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addOptions();
                      }}
                    >
                      Add Options
                    </button>
                  </div>
                </div>
                {/*//////////// Question  Answer///////////*/}
                <h4 className="col-md-12">Question Answers</h4>
                {qna.map((element, index) => {
                  return (
                    <>
                      <div className="col-md-11">
                        <div>
                          <label
                            className="form-label form-label-outside"
                            for={"addInfoTitle_" + index}
                          >
                            Question
                          </label>
                          <textarea
                            className="form-input"
                            id={"addInfoTitle_" + index}
                            data-constraints="@Required"
                            value={qna[index]["question"]}
                            onChange={(e) => {
                              handleChangeQna(
                                e.target.value,
                                index,
                                "question"
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 mt-5">
                        <button
                          className="navbar-cart-remove"
                          onClick={(e) => {
                            e.preventDefault();
                            removeQnaInstance(index);
                          }}
                        >
                          <svg
                            className="icon-svg"
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M5 0H11V2H16V4H0V2H5V0Z"></path>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M2 20H14V6H2V20ZM12 18H4V8H12V18Z"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div>
                          <label
                            className="form-label form-label-outside"
                            for={"answer" + index}
                          >
                            Answer
                          </label>
                          <textarea
                            className="form-input"
                            id={"answer_" + index}
                            data-constraints="@Required"
                            value={qna[index]["answer"]}
                            onChange={(e) => {
                              handleChangeQna(e.target.value, index, "answer");
                            }}
                          />
                        </div>
                      </div>
                      <hr className="col-md-12"></hr>
                    </>
                  );
                })}
                <div className="col-md-12">
                  <div>
                    <button
                      className="btn btn-block btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        addQna();
                      }}
                    >
                      Add QNA
                    </button>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="contact-name"
                    >
                      Tags (Comma Separated)
                    </label>
                    <input
                      className="form-input"
                      id="contact-name"
                      type="text"
                      name="tags"
                      data-constraints="@Required"
                      value={tags.join(", ")}
                      onChange={(e) => {
                        let value = e.target.value;
                        value = value.replaceAll("\n", ",");
                        if (value?.length === 0) {
                          setTags([]);
                        } else if (value[value.length - 1] === ",") {
                          const newTags = value.split(", ").map((tag) => {
                            return tag.trim();
                          });
                          setTags(newTags);
                        } else {
                          setTags(value.split(", "));
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-wrap">
                    <label
                      className="form-label form-label-outside"
                      htmlFor="contact-name"
                    >
                      Polularity
                    </label>
                    <input
                      className="form-input"
                      type="text"
                      name="popularity"
                      value={popularity}
                      onChange={(e) => {
                        const value = e.target.value;
                        const numbers = /^[0-9]*$/;

                        if (value?.length === 0) {
                          setPopularity(0);
                        } else if (value?.length > 0 && value.match(numbers)) {
                          setPopularity(parseInt(value));
                        }
                      }}
                    />
                  </div>
                </div>
                {/* {isEdit() && (
                  <>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label
                          className="form-label form-label-outside"
                          htmlFor="contact-name"
                        >
                          Popularity
                        </label>
                        <input
                          className="form-input"
                          id="contact-name"
                          type="text"
                          name="popularity"
                          data-constraints="@Required"
                          value={popularity}
                          onChange={(e) => {
                            setPopularity(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-wrap">
                        <label
                          className="form-label form-label-outside"
                          htmlFor="contact-name"
                        >
                          Rating
                        </label>
                        <input
                          className="form-input"
                          id="contact-name"
                          type="text"
                          name="popularity"
                          data-constraints="@Required"
                          value={rating}
                          onChange={(e) => {
                            setRating(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </>
                )} */}

                <div className="col-md-12 mt-5">
                  <button
                    className="btn btn-block btn-primary"
                    color="green"
                    onClick={handleSubmit}
                  >
                    {isEdit() ? "Update Product" : "Add Product"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AddProduct;
