import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_ENC_SECRET; // Use a secure key

const tokenizeCard = async (cardNumber, expirationDate, cvv) => {
  cardNumber = cardNumber.replaceAll(" ", "");
  expirationDate = expirationDate.replaceAll("/", "");
  return CryptoJS.AES.encrypt(
    `${cardNumber}|${expirationDate}|${cvv}`,
    secretKey
  ).toString();
};

export default tokenizeCard;
