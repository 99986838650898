import React from "react";
import { useEffect } from "react";
import { ImportMainJs } from "../helper/ImportScript";

function AboutUs(props) {
  return (
    <>
      <section className="section section-sm bg-white">
        <div className="container">
          <div className="text-center">
            <h3>Star Distributors</h3>
            <p>
              A smoke shop specializing in high-quality products for Hemp
              enthusiasts.
              <br className="d-none d-xl-block" /> From premium strains to
              smoking accessories, we offer everything you need for an elevated
              smoking experience.
            </p>
          </div>
          <div className="row row-30 justify-content-center">
            <div className="col-auto">
              <div className="blurb">
                <div className="blurb-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.95371 1.08027C5.29756 0.41719 5.98224 0.000976562 6.72917 0.000976562H17.0741C17.821 0.000976562 18.5057 0.41719 18.8495 1.08027L23.593 10.2273H33.274C34.0209 10.2273 34.7056 10.6436 35.0494 11.3066L39.5225 19.9325C39.8219 20.5098 39.8219 21.1966 39.5225 21.7739L35.0494 30.3997C34.7056 31.0628 34.0209 31.479 33.274 31.479H22.706L18.8464 38.9217C18.5026 39.5848 17.8179 40.001 17.0709 40.001H6.72605C5.97912 40.001 5.29443 39.5848 4.95058 38.9217L0.477455 30.2959C0.178081 29.7185 0.178082 29.0317 0.477455 28.4544L4.86275 19.998L0.480585 11.5475C0.181211 10.9702 0.181211 10.2834 0.480585 9.7061L4.95371 1.08027ZM16.4102 21.2526L20.8437 29.8022L16.853 37.4976H6.94395L2.73184 29.3751L6.94395 21.2526H16.4102ZM23.5898 28.9757H33.0561L37.2682 20.8532L33.0561 12.7307H23.147L19.1563 20.4262L23.5898 28.9757ZM21.0683 10.6268L16.8562 18.7493H6.94708L2.73497 10.6268L6.94708 2.50431H16.8562L21.0683 10.6268Z"
                      fill="#C58940"
                    ></path>
                  </svg>
                </div>
                <div className="blurb-text">Hemp</div>
              </div>
            </div>
            <div className="col-auto">
              <div className="blurb">
                <div className="blurb-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9 1H31.3146V8.7189H28.4353V31.5247H27.3556C28.4353 31.5247 28.4353 31.5241 28.4353 31.5247L28.4353 31.5276L28.4353 31.5309L28.4353 31.5393L28.4349 31.5621C28.4345 31.58 28.4339 31.6033 28.4326 31.6316C28.4302 31.6882 28.4256 31.7652 28.417 31.8596C28.3997 32.048 28.3661 32.3082 28.3002 32.6166C28.1691 33.23 27.9057 34.0562 27.3714 34.8895C26.2605 36.6223 24.1018 38.1911 20.1573 38.1911C16.2129 38.1911 14.0542 36.6223 12.9432 34.8895C12.4089 34.0562 12.1456 33.23 12.0145 32.6166C11.9486 32.3082 11.9149 32.048 11.8976 31.8596C11.889 31.7652 11.8844 31.6882 11.882 31.6316C11.8808 31.6033 11.8801 31.58 11.8797 31.5621L11.8794 31.5393L11.8793 31.5309L11.8793 31.5276L11.8793 31.5261C11.8793 31.5254 11.8793 31.5247 12.959 31.5247L11.8793 31.5261L11.8793 8.7189H9V1ZM14.0388 31.5196L14.0396 31.544C14.0407 31.5696 14.0432 31.6132 14.0486 31.6724C14.0594 31.7909 14.082 31.9693 14.1286 32.1872C14.2224 32.6264 14.4089 33.2036 14.7745 33.7738C15.4631 34.8478 16.9035 36.0859 20.1573 36.0859C23.4111 36.0859 24.8516 34.8478 25.5402 33.7738C25.9057 33.2036 26.0922 32.6264 26.1861 32.1872C26.2326 31.9693 26.2552 31.7909 26.2661 31.6724C26.2715 31.6132 26.2739 31.5696 26.275 31.544L26.2759 31.5196V6.61375H29.1552V3.10515H11.1595V6.61375H14.0388V15.0344H20.1573V17.1395H14.0388V21.3498H20.1573V23.455H14.0388V27.6653H20.1573V29.7705H14.0388L14.0388 31.5196Z"
                      fill="#C58940"
                    ></path>
                  </svg>
                </div>
                <div className="blurb-text">Herbal remedies</div>
              </div>
            </div>
            <div className="col-auto">
              <div className="blurb">
                <div className="blurb-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      d="M23.8495 20.3774C23.8495 23.118 21.6278 25.3397 18.8871 25.3397C16.1465 25.3397 13.9248 23.118 13.9248 20.3774C13.9248 17.6367 16.1465 15.415 18.8871 15.415C21.6278 15.415 23.8495 17.6367 23.8495 20.3774Z"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M22.3583 31.4226C22.3583 32.5167 21.4713 33.4037 20.3772 33.4037C19.283 33.4037 18.396 32.5167 18.396 31.4226C18.396 30.3284 19.283 29.4414 20.3772 29.4414C21.4713 29.4414 22.3583 30.3284 22.3583 31.4226Z"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M7.45291 28.5761C7.45291 30.0818 6.23224 31.3025 4.72646 31.3025C3.22068 31.3025 2 30.0818 2 28.5761C2 27.0703 3.22068 25.8496 4.72646 25.8496C6.23224 25.8496 7.45291 27.0703 7.45291 28.5761Z"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <circle
                      cx="33.793"
                      cy="5.47175"
                      r="3.47175"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></circle>
                    <circle
                      cx="9.94357"
                      cy="9.94308"
                      r="1.98116"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></circle>
                    <circle
                      cx="35.2834"
                      cy="35.2829"
                      r="1.98116"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></circle>
                    <path
                      d="M11.4341 11.4336L15.9058 15.9053"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M31.5569 7.70801L21.8682 17.3968"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M6.2168 27.085L14.415 22.6133"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M20.3772 29.9324L19.5179 25.4605"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                    <path
                      d="M33.7931 33.793L23.3583 22.3738"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
                <div className="blurb-text">Vaporizers</div>
              </div>
            </div>
            <div className="col-auto">
              <div className="blurb">
                <div className="blurb-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M19.5969 6.39269L8.82167 12.5843L4.43991 9.85868C3.97095 9.56697 3.3543 9.71066 3.06259 10.1796V10.1796C2.77088 10.6486 2.91457 11.2652 3.38353 11.5569L7.9427 14.3929V26.4514L2.91452 28.6756C2.40945 28.899 2.18111 29.4896 2.40453 29.9946V29.9946C2.62795 30.4997 3.21851 30.7281 3.72358 30.5046L8.9318 28.2008L19.5974 34.3295V38.993C19.5974 39.5453 20.0451 39.993 20.5974 39.993V39.993C21.1497 39.993 21.5974 39.5453 21.5974 38.993V34.3294L32.0532 28.3214L36.7732 30.9312C37.2566 31.1985 37.865 31.0233 38.1323 30.54V30.54C38.3995 30.0567 38.2243 29.4482 37.741 29.181L33.2521 26.6989V14.0207L37.741 11.5386C38.2243 11.2714 38.3995 10.6629 38.1323 10.1796V10.1796C37.865 9.69629 37.2566 9.52112 36.7732 9.78837L32.0512 12.3993L21.5969 6.39217V1C21.5969 0.447716 21.1492 0 20.5969 0V0C20.0446 0 19.5969 0.447715 19.5969 1V6.39269ZM9.9427 14.2468L20.5974 8.12449L31.2521 14.2468V26.4751L20.5974 32.5974L9.9427 26.4751V14.2468Z"
                      fill="#C58940"
                    ></path>
                  </svg>
                </div>
                <div className="blurb-text">Glass pipes</div>
              </div>
            </div>
            <div className="col-auto">
              <div className="blurb">
                <div className="blurb-icon">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <path
                      d="M31.4298 24.9133C31.4298 30.9825 26.4242 35.9303 20.2149 35.9303C14.0056 35.9303 9 30.9825 9 24.9133C9 23.8235 9.40029 22.3521 10.1311 20.6173C10.8526 18.9044 11.8566 17.0258 12.975 15.1508C15.2112 11.4021 17.8591 7.74398 19.4883 5.58099C19.8595 5.08806 20.5703 5.08806 20.9416 5.581C22.5707 7.74398 25.2186 11.4021 27.4548 15.1508C28.5732 17.0258 29.5772 18.9044 30.2987 20.6173C31.0295 22.3521 31.4298 23.8235 31.4298 24.9133Z"
                      stroke="#C58940"
                      strokeWidth="2"
                    ></path>
                  </svg>
                </div>
                <div className="blurb-text">Dab rigs</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="row no-gutters">
          <div className="col-lg-3 col-xs-6">
            <div className="thumb context-dark">
              <figure className="thumb-figure">
                <img
                  src="/images/home-01-480x560.jpg"
                  alt=""
                  width="480"
                  height="560"
                />
              </figure>
              <div className="thumb-body">
                <h3 className="thumb-text">Kratom</h3>
                <a className="btn btn-white" href="/products/kratom">
                  Show More Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="thumb context-dark">
              <figure className="thumb-figure">
                <img
                  src="/images/home-02-480x560.jpg"
                  alt=""
                  width="480"
                  height="560"
                />
              </figure>
              <div className="thumb-body">
                <h3 className="thumb-text">CBD</h3>
                <a className="btn btn-white" href="/products/cbd">
                  Show More Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="thumb context-dark">
              <figure className="thumb-figure">
                <img
                  src="/images/home-03-480x560.jpg"
                  alt=""
                  width="480"
                  height="560"
                />
              </figure>
              <div className="thumb-body">
                <h3 className="thumb-text">Δ8</h3>
                <a className="btn btn-white" href="/products/delta8">
                  Show More Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xs-6">
            <div className="thumb context-dark">
              <figure className="thumb-figure">
                <img
                  src="/images/home-04-480x560.jpg"
                  alt=""
                  width="480"
                  height="560"
                />
              </figure>
              <div className="thumb-body">
                <h3 className="thumb-text">Edibles</h3>
                <a className="btn btn-white" href="/products/edibles">
                  Show More Details
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-lg bg-primary-light">
        <div className="container">
          <div className="row row-50 align-items-center">
            <div className="col-md-6">
              <h3>
                Feel Elevated, Blaze Up,
                <br className="d-none d-xl-block" />
                Get Stoned
              </h3>
              <h5>
                Our selection of edibles, concentrates, and pre-rolls is always
                changing to offer you the latest and greatest in the Hemp
                industry.
              </h5>
              <h5>
                Whether you're a seasoned smoker or new to the scene, we have
                products that will suit your needs and preferences.
              </h5>
              <a className="btn btn-primary" href="/products">
                More Info
              </a>
            </div>
            <div className="col-md-6">
              <img
                src="/images/home-05-880x550.jpg"
                alt=""
                width="880"
                height="550"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="section section-xxl bg-image section-overlay-1"
        style={{ backgroundImage: "url(images/bg-01-1920x900.jpg)" }}
      >
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-6 col-sm-7">
              <h2 className="h1">
                Come visit us and experience the best smoke shop in town!
              </h2>
              <h5>
                Our knowledgeable and friendly staff is always available to
                answer any questions you may have and help you find the perfect
                product.
              </h5>
              <a className="btn btn-primary" href="/about-us">
                Know More
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-sm bg-white">
        <div className="container">
          <div
            className="owl-carousel owl-theme owl-carousel-style-2"
            data-items="1"
            data-owl='{"dots":true,"nav":false,"autoplayTimeout":3500000,"navText":["&lt;svg class=\"icon-svg-outline\" width=\"15\" height=\"26\" viewBox=\"0 0 15 26\" fill=\"none\"&gt;\n&lt;path d=\"M14 25L2 13L14 1\" stroke-width=\"2\" stroke-linecap=\"round\"/&gt;\n&lt;/svg&gt;","&lt;svg class=\"icon-svg-outline\" width=\"15\" height=\"26\" viewBox=\"0 0 15 26\" fill=\"none\"&gt;\n&lt;svg width=\"15\" height=\"26\" viewBox=\"0 0 15 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"&gt;&lt;path d=\"M1 25L13 13L0.999999 1\" stroke=\"#084B6D\" stroke-width=\"2\" stroke-linecap=\"round\"/&gt;\n&lt;path d=\"M1 25L13 13L0.999999 1\" stroke-width=\"2\" stroke-linecap=\"round\"/&gt;&lt;/svg&gt;"]}'
          >
            <div className="testimonials">
              <div className="testimonials-rating">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
              </div>
              <blockquote className="testimonials-body">
                <p className="testimonials-text h4">
                  "I've been ordering from this online weed shop for over a year
                  now, and I have never been disappointed. Their product is
                  always top-notch, and their customer service is second to
                  none. I highly recommend them to anyone looking for a reliable
                  and trustworthy source of high-quality Hemp."
                </p>
                <div className="testimonials-author">
                  <cite>Jesica Alba</cite>
                </div>
              </blockquote>
            </div>
            <div className="testimonials">
              <div className="testimonials-rating">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
              </div>
              <blockquote className="testimonials-body">
                <p className="testimonials-text h4">
                  "I was a little hesitant to order weed online, but this shop
                  made the process so easy and convenient. Their website is
                  user-friendly, their shipping is fast and discreet, and their
                  product is exceptional. I'll definitely be a repeat customer!"
                </p>
                <div className="testimonials-author">
                  <cite>John Doe</cite>
                </div>
              </blockquote>
            </div>
            <div className="testimonials">
              <div className="testimonials-rating">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
              </div>
              <blockquote className="testimonials-body">
                <p className="testimonials-text h4">
                  "I've tried a lot of different online weed shops, and this one
                  is by far the best. Their selection is incredible, their
                  prices are competitive, and their customer service is
                  exceptional. I love that I can trust them to always provide me
                  with the best products and service. Highly recommend!"
                </p>
                <div className="testimonials-author">
                  <cite>Sam Kromstain</cite>
                </div>
              </blockquote>
            </div>
            <div className="testimonials">
              <div className="testimonials-rating">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.9742 6.93419C17.9121 6.74325 17.7472 6.6041 17.5485 6.57528L11.9684 5.7644L9.47288 0.708003C9.38408 0.528003 9.20074 0.414062 9.00003 0.414062C8.79929 0.414062 8.61598 0.528003 8.52715 0.708003L6.03148 5.7644L0.451505 6.57528C0.252908 6.6041 0.0878505 6.74325 0.0258351 6.93415C-0.0362154 7.12508 0.0155344 7.33465 0.159288 7.47475L4.1969 11.4106L3.24389 16.9682C3.20992 17.1661 3.29128 17.366 3.45366 17.484C3.54552 17.5507 3.65433 17.5847 3.76367 17.5847C3.84762 17.5847 3.93185 17.5647 4.00899 17.5241L9 14.9001L13.9908 17.524C14.1685 17.6175 14.3838 17.6019 14.5462 17.4839C14.7085 17.366 14.7899 17.166 14.756 16.9681L13.8027 11.4106L17.8407 7.47471C17.9845 7.33465 18.0362 7.12508 17.9742 6.93419Z"
                    fill="#C58940"
                  ></path>
                </svg>
              </div>
              <blockquote className="testimonials-body">
                <p className="testimonials-text h4">
                  "I was blown away by the quality of the weed I received from
                  this online shop. Not only was it some of the best I've ever
                  had, but the packaging was so professional and discreet that
                  even my nosy neighbors had no idea what was inside. I also
                  appreciated the extra little touches, like the free rolling
                  papers that came with my order. Overall, I'm extremely
                  impressed with this shop and will definitely be ordering from
                  them again in the future."
                </p>
                <div className="testimonials-author">
                  <cite>Edna Barton</cite>
                </div>
              </blockquote>
            </div>
          </div>
        </div>
      </section>

      <section className="section section-md bg-white">
        <div className="container">
          <div className="row no-gutters" data-lightgallery="group">
            <div className="col-md-6">
              <div className="row no-gutters">
                <div className="col-xs-6">
                  <a
                    className="thumb context-dark"
                    data-lightgallery="item"
                    href="/images/gallery-01-original.jpg"
                  >
                    <figure className="thumb-figure">
                      <img
                        src="/images/gallery-01-290x290.jpg"
                        alt=""
                        width="290"
                        height="290"
                      />
                    </figure>
                    <div className="thumb-body">
                      <svg
                        width="31"
                        height="18"
                        viewBox="0 0 31 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.1972 12.6533C17.2275 12.6533 18.8733 11.0237 18.8733 9.01344C18.8733 8.96752 18.8715 8.92202 18.8698 8.87646C18.5333 9.02806 18.1598 9.11311 17.7661 9.11311C16.2917 9.11311 15.0966 7.92966 15.0966 6.46982C15.0966 6.07992 15.1825 5.71017 15.3356 5.37693C15.2896 5.37524 15.2437 5.37348 15.1972 5.37348C13.167 5.37348 11.5212 7.00312 11.5212 9.01338C11.5212 11.0236 13.167 12.6533 15.1972 12.6533Z"
                          fill="white"
                        ></path>
                        <path
                          d="M29.9311 8.55782C28.4623 6.0438 26.3503 3.93532 23.8233 2.46025C21.2191 0.940166 18.2363 0.136719 15.1973 0.136719C12.1582 0.136719 9.17543 0.940166 6.57127 2.46025C4.04424 3.93526 1.93223 6.04374 0.463472 8.55782L0.197266 9.01347L0.463472 9.46913C1.93223 11.9832 4.04424 14.0916 6.57127 15.5667C9.17543 17.0868 12.1582 17.8902 15.1973 17.8902C18.2363 17.8902 21.2191 17.0868 23.8233 15.5667C26.3503 14.0917 28.4623 11.9832 29.9311 9.46913L30.1973 9.01347L29.9311 8.55782ZM22.3241 9.01347C22.3241 12.9046 19.1269 16.0702 15.1973 16.0702C11.2676 16.0702 8.07048 12.9046 8.07048 9.01347C8.07048 5.12236 11.2676 1.95673 15.1973 1.95673C19.1269 1.95673 22.3241 5.12236 22.3241 9.01347ZM2.33169 9.01347C3.73723 6.79386 5.68975 5.0077 7.95554 3.78748C6.8727 5.25461 6.23246 7.06127 6.23246 9.01347C6.23246 10.9657 6.8727 12.7723 7.95554 14.2395C5.68975 13.0192 3.73723 11.2331 2.33169 9.01347ZM22.439 14.2395C23.5218 12.7723 24.1621 10.9657 24.1621 9.01347C24.1621 7.06127 23.5218 5.25461 22.439 3.78748C24.7048 5.0077 26.6573 6.79386 28.0628 9.01347C26.6573 11.2331 24.7048 13.0192 22.439 14.2395Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
                <div className="col-xs-6">
                  <a
                    className="thumb context-dark"
                    data-lightgallery="item"
                    href="/images/gallery-02-original.jpg"
                  >
                    <figure className="thumb-figure">
                      <img
                        src="/images/gallery-02-290x290.jpg"
                        alt=""
                        width="290"
                        height="290"
                      />
                    </figure>
                    <div className="thumb-body">
                      <svg
                        width="31"
                        height="18"
                        viewBox="0 0 31 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.1972 12.6533C17.2275 12.6533 18.8733 11.0237 18.8733 9.01344C18.8733 8.96752 18.8715 8.92202 18.8698 8.87646C18.5333 9.02806 18.1598 9.11311 17.7661 9.11311C16.2917 9.11311 15.0966 7.92966 15.0966 6.46982C15.0966 6.07992 15.1825 5.71017 15.3356 5.37693C15.2896 5.37524 15.2437 5.37348 15.1972 5.37348C13.167 5.37348 11.5212 7.00312 11.5212 9.01338C11.5212 11.0236 13.167 12.6533 15.1972 12.6533Z"
                          fill="white"
                        ></path>
                        <path
                          d="M29.9311 8.55782C28.4623 6.0438 26.3503 3.93532 23.8233 2.46025C21.2191 0.940166 18.2363 0.136719 15.1973 0.136719C12.1582 0.136719 9.17543 0.940166 6.57127 2.46025C4.04424 3.93526 1.93223 6.04374 0.463472 8.55782L0.197266 9.01347L0.463472 9.46913C1.93223 11.9832 4.04424 14.0916 6.57127 15.5667C9.17543 17.0868 12.1582 17.8902 15.1973 17.8902C18.2363 17.8902 21.2191 17.0868 23.8233 15.5667C26.3503 14.0917 28.4623 11.9832 29.9311 9.46913L30.1973 9.01347L29.9311 8.55782ZM22.3241 9.01347C22.3241 12.9046 19.1269 16.0702 15.1973 16.0702C11.2676 16.0702 8.07048 12.9046 8.07048 9.01347C8.07048 5.12236 11.2676 1.95673 15.1973 1.95673C19.1269 1.95673 22.3241 5.12236 22.3241 9.01347ZM2.33169 9.01347C3.73723 6.79386 5.68975 5.0077 7.95554 3.78748C6.8727 5.25461 6.23246 7.06127 6.23246 9.01347C6.23246 10.9657 6.8727 12.7723 7.95554 14.2395C5.68975 13.0192 3.73723 11.2331 2.33169 9.01347ZM22.439 14.2395C23.5218 12.7723 24.1621 10.9657 24.1621 9.01347C24.1621 7.06127 23.5218 5.25461 22.439 3.78748C24.7048 5.0077 26.6573 6.79386 28.0628 9.01347C26.6573 11.2331 24.7048 13.0192 22.439 14.2395Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
                <div className="col-12">
                  <a
                    className="thumb context-dark"
                    data-lightgallery="item"
                    href="/images/gallery-03-original.jpg"
                  >
                    <figure className="thumb-figure">
                      <img
                        src="/images/gallery-03-580x290.jpg"
                        alt=""
                        width="580"
                        height="290"
                      />
                    </figure>
                    <div className="thumb-body">
                      <svg
                        width="31"
                        height="18"
                        viewBox="0 0 31 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.1972 12.6533C17.2275 12.6533 18.8733 11.0237 18.8733 9.01344C18.8733 8.96752 18.8715 8.92202 18.8698 8.87646C18.5333 9.02806 18.1598 9.11311 17.7661 9.11311C16.2917 9.11311 15.0966 7.92966 15.0966 6.46982C15.0966 6.07992 15.1825 5.71017 15.3356 5.37693C15.2896 5.37524 15.2437 5.37348 15.1972 5.37348C13.167 5.37348 11.5212 7.00312 11.5212 9.01338C11.5212 11.0236 13.167 12.6533 15.1972 12.6533Z"
                          fill="white"
                        ></path>
                        <path
                          d="M29.9311 8.55782C28.4623 6.0438 26.3503 3.93532 23.8233 2.46025C21.2191 0.940166 18.2363 0.136719 15.1973 0.136719C12.1582 0.136719 9.17543 0.940166 6.57127 2.46025C4.04424 3.93526 1.93223 6.04374 0.463472 8.55782L0.197266 9.01347L0.463472 9.46913C1.93223 11.9832 4.04424 14.0916 6.57127 15.5667C9.17543 17.0868 12.1582 17.8902 15.1973 17.8902C18.2363 17.8902 21.2191 17.0868 23.8233 15.5667C26.3503 14.0917 28.4623 11.9832 29.9311 9.46913L30.1973 9.01347L29.9311 8.55782ZM22.3241 9.01347C22.3241 12.9046 19.1269 16.0702 15.1973 16.0702C11.2676 16.0702 8.07048 12.9046 8.07048 9.01347C8.07048 5.12236 11.2676 1.95673 15.1973 1.95673C19.1269 1.95673 22.3241 5.12236 22.3241 9.01347ZM2.33169 9.01347C3.73723 6.79386 5.68975 5.0077 7.95554 3.78748C6.8727 5.25461 6.23246 7.06127 6.23246 9.01347C6.23246 10.9657 6.8727 12.7723 7.95554 14.2395C5.68975 13.0192 3.73723 11.2331 2.33169 9.01347ZM22.439 14.2395C23.5218 12.7723 24.1621 10.9657 24.1621 9.01347C24.1621 7.06127 23.5218 5.25461 22.439 3.78748C24.7048 5.0077 26.6573 6.79386 28.0628 9.01347C26.6573 11.2331 24.7048 13.0192 22.439 14.2395Z"
                          fill="white"
                        ></path>
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <a
                className="thumb context-dark"
                data-lightgallery="item"
                href="/images/gallery-04-original.jpg"
              >
                <figure className="thumb-figure">
                  <img
                    src="/images/gallery-04-580x580.jpg"
                    alt=""
                    width="580"
                    height="580"
                  />
                </figure>
                <div className="thumb-body">
                  <svg
                    width="31"
                    height="18"
                    viewBox="0 0 31 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.1972 12.6533C17.2275 12.6533 18.8733 11.0237 18.8733 9.01344C18.8733 8.96752 18.8715 8.92202 18.8698 8.87646C18.5333 9.02806 18.1598 9.11311 17.7661 9.11311C16.2917 9.11311 15.0966 7.92966 15.0966 6.46982C15.0966 6.07992 15.1825 5.71017 15.3356 5.37693C15.2896 5.37524 15.2437 5.37348 15.1972 5.37348C13.167 5.37348 11.5212 7.00312 11.5212 9.01338C11.5212 11.0236 13.167 12.6533 15.1972 12.6533Z"
                      fill="white"
                    ></path>
                    <path
                      d="M29.9311 8.55782C28.4623 6.0438 26.3503 3.93532 23.8233 2.46025C21.2191 0.940166 18.2363 0.136719 15.1973 0.136719C12.1582 0.136719 9.17543 0.940166 6.57127 2.46025C4.04424 3.93526 1.93223 6.04374 0.463472 8.55782L0.197266 9.01347L0.463472 9.46913C1.93223 11.9832 4.04424 14.0916 6.57127 15.5667C9.17543 17.0868 12.1582 17.8902 15.1973 17.8902C18.2363 17.8902 21.2191 17.0868 23.8233 15.5667C26.3503 14.0917 28.4623 11.9832 29.9311 9.46913L30.1973 9.01347L29.9311 8.55782ZM22.3241 9.01347C22.3241 12.9046 19.1269 16.0702 15.1973 16.0702C11.2676 16.0702 8.07048 12.9046 8.07048 9.01347C8.07048 5.12236 11.2676 1.95673 15.1973 1.95673C19.1269 1.95673 22.3241 5.12236 22.3241 9.01347ZM2.33169 9.01347C3.73723 6.79386 5.68975 5.0077 7.95554 3.78748C6.8727 5.25461 6.23246 7.06127 6.23246 9.01347C6.23246 10.9657 6.8727 12.7723 7.95554 14.2395C5.68975 13.0192 3.73723 11.2331 2.33169 9.01347ZM22.439 14.2395C23.5218 12.7723 24.1621 10.9657 24.1621 9.01347C24.1621 7.06127 23.5218 5.25461 22.439 3.78748C24.7048 5.0077 26.6573 6.79386 28.0628 9.01347C26.6573 11.2331 24.7048 13.0192 22.439 14.2395Z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
