import React from "react";

function AgeVerification() {
  const setAge = () => {
    localStorage.setItem("age_gate", 21);
    window.location.reload();
  };

  const exitWebPage = () => {
    window.location.href = "https://www.google.com/";
  };
  return (
    <div
      className="modal modal-login fade"
      data-modal-autoshow="1000"
      id="modal-login"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <section className="section section-md bg-white text-center">
              <div className="container">
                <img src="/images/logo.svg" alt="" width="490" height="125" />
                <h3>Age Verification Required</h3>
                <div className="mt-3 big">
                  Welcome to Snoopydoopy.com! Please confirm your age to
                  proceed.
                </div>
                <div className="group-xl">
                  <div className="btn btn-primary" onClick={setAge}>
                    I am over 21
                  </div>
                  <div className="btn btn-tertiary" onClick={exitWebPage}>
                    I am under 21
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgeVerification;
