import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { isUserLoggedIn, setPageTitle } from "../common";
import { useMutation } from "@apollo/client";
import queries from "../queries";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";

function Signup(props) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [memberType, setMemberType] = useState(4);
  const [signup] = useMutation(queries.member.SIGNUP);
  const [login] = useMutation(queries.member.LOGIN);
  const navigate = useNavigate();

  async function handleSignup(e) {
    e.preventDefault();
    try {
      if (firstName?.length == 0) {
        throw "First Name is required";
      }
      if (lastName?.length == 0) {
        throw "Last Name is required";
      }
      if (email?.length == 0) {
        throw "Email is required";
      }
      if (password?.length == 0) {
        throw "Poassword is required";
      }
      if (confirmPassword != password) {
        throw "Password mismatch";
      }
      const { data } = await signup({
        variables: {
          signupArgs: {
            fname: firstName,
            lname: lastName,
            email: email,
            password: password,
            memberType: memberType,
          },
        },
      });
      if (data?.signup?._id != null) {
        const { data } = await login({
          variables: {
            email: email,
            password: password,
          },
        });
        if (data?.login?.token != null) {
          localStorage.setItem("token", data?.login?.token);
          window.location.href = "/";
        }
      }
    } catch (e) {
      if (typeof e == "string") {
        e = {
          message: e,
        };
      }
      Swal.fire({
        icon: "error",
        title: e.message,
      });
    }
  }

  setPageTitle("Signup");

  useEffect(() => {
    if (isUserLoggedIn()) {
      navigate("/");
    }
    ImportMainJs();
  }, []);

  return (
    // <div className="hiraola-login-register_area">
    //   <div className="container">
    //     <form action="#">
    //       <div className="login-form">
    //         <h4 className="login-title">Register</h4>
    //         <div className="row">
    //           <div className="col-md-6 col-12 mb--20">
    //             <label>First Name</label>
    //             <input
    //               type="text"
    //               placeholder="First Name"
    //               value={firstName}
    //               onChange={(e) => setFirstName(e.target.value)}
    //             />
    //           </div>
    //           <div className="col-md-6 col-12 mb--20">
    //             <label>Last Name</label>
    //             <input
    //               type="text"
    //               placeholder="Last Name"
    //               value={lastName}
    //               onChange={(e) => setLastName(e.target.value)}
    //             />
    //           </div>
    //           <div className="col-md-12">
    //             <label>Email Address*</label>
    //             <input
    //               type="email"
    //               placeholder="Email Address"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //             />
    //           </div>
    //           <div className="col-md-6">
    //             <label>Password</label>
    //             <input
    //               type="password"
    //               placeholder="Password"
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //             />
    //           </div>
    //           <div className="col-md-6">
    //             <label>Confirm Password</label>
    //             <input
    //               type="password"
    //               placeholder="Confirm Password"
    //               value={confirmPassword}
    //               onChange={(e) => setConfirmPassword(e.target.value)}
    //             />
    //           </div>
    //           {/* <div className="col-md-6">
    //             <label>Member Type</label>
    //             <select
    //               className="nice-select select-search-category"
    //               style={{ width: "100%" }}
    //               onChange={(e) => {
    //                 setMemberType(parseInt(e.target.value));
    //               }}
    //               value={memberType}
    //             >
    //               <option value="4">Customer</option>
    //               <option value="5">Business</option>
    //             </select>
    //           </div> */}
    //           <div className="col-12" onClick={handleSignup}>
    //             <button className="hiraola-register_btn">Register</button>
    //           </div>
    //         </div>
    //       </div>
    //     </form>
    //   </div>
    // </div>
    <section className="section section-md bg-white text-center">
      <div className="container">
        <div className="row justify-content-md-center">
          <div className="col-md-9 col-lg-7 col-xl-5">
            <h3>Signup for new account</h3>
            {/* <div className="group-sm group-custom">
                <a
                  className="btn btn-login btn-facebook btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-facebook"></span>Facebook
                </a>
                <a
                  className="btn btn-login btn-twitter btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-twitter"></span>Twitter
                </a>
                <a
                  className="btn btn-login btn-google btn-icon btn-icon-left btn-round"
                  href="#"
                >
                  <span className="icon fa fa-google-plus"></span>Google+
                </a>
              </div> */}
            {/* <div className="text-decoration-lines">
                <span className="text-decoration-lines-content">or</span>
              </div> */}

            <form className="rd-form rd-mailform rd-form-centered">
              <div className="form-wrap">
                <input
                  className="form-input"
                  id="login-fname"
                  type="text"
                  name="fname"
                  data-constraints="@Required"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <label className="form-label" htmlFor="login-fname">
                  First Name
                </label>
              </div>
              <div className="form-wrap">
                <input
                  className="form-input"
                  id="login-lname"
                  type="text"
                  name="lname"
                  data-constraints="@Required"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <label className="form-label" htmlFor="login-lname">
                  Last Name
                </label>
              </div>
              <div className="form-wrap">
                <input
                  className="form-input"
                  id="login-email"
                  type="email"
                  name="email"
                  data-constraints="@Email @Required"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label className="form-label" htmlFor="login-email">
                  E-mail
                </label>
              </div>
              <div className="form-wrap">
                <input
                  className="form-input"
                  id="login-password"
                  type="password"
                  name="password"
                  data-constraints="@Required"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label className="form-label" htmlFor="login-password">
                  Password
                </label>
              </div>
              <div className="form-wrap">
                <input
                  className="form-input"
                  id="login-confirm-password"
                  type="password"
                  name="confirm-password"
                  data-constraints="@Required"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <label className="form-label" htmlFor="login-confirm-password">
                  Confirm Password
                </label>
              </div>
              <button
                className="btn btn-block btn-primary"
                onClick={handleSignup}
              >
                Signup
              </button>
            </form>
            <p className="big">
              Already a member? <a href="/login">Login</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
