const scripts = ["/js/core.min.js", "/js/script.js"];
export const ImportScripts = () => {
  scripts.forEach(async (url, inx) => {
    const oldScript = document.getElementById("script" + inx);
    const script = document.createElement("script");
    script.id = "script" + inx;
    script.type = "text/javascript";
    script.src = url;
    script.async = false;
    script.defer = false;
    if (oldScript != null) {
      // document.body.replaceChild(script, oldScript);
    } else {
      document.body.appendChild(script);
    }
    // const response = await fetch(url);
    // const script = await response.text();
    // eval(script);
  });
};

export const ImportMainJs = async () => {
  // const response = await fetch("/js/script.js");
  // const scriptText = await response.text();
  // eval(scriptText);

  const oldScript = document.getElementById("mainJs");
  const script = document.createElement("script");
  script.id = "mainJs";
  script.type = "text/javascript";
  script.src = "/js/script.js";
  script.defer = true;

  setTimeout(() => {
    if (document.body.contains(oldScript)) {
      document.body.replaceChild(script, oldScript);
    } else {
      document.body.appendChild(script);
    }
  }, 500);

  // let scriptElement = document.getElementById("mainJs");
  // if (!scriptElement) {
  //   scriptElement = document.createElement("script");
  //   scriptElement.id = "mainJs";
  //   document.body.appendChild(scriptElement);
  // }
  // scriptElement.textContent = scriptText;
};
