import { gql, useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { closeLoading, showLoading } from "../../common";
import { uploadFile } from "../../ImageUploadHelper";
import queries from "../../queries";

const UPLOAD_IMAGE = gql`
  mutation ($files: [Upload!]!) {
    uploadFiles(files: $files) {
      success
    }
  }
`;

function ImageUploader({ attachImageURLsToProduct, images: imageURLs }) {
  async function uploadImagesToAssets(images) {
    try {
      showLoading("Uploading images...");
      const newImageUrls = [];
      for (let i = 0; i < images.length; i++) {
        const image = images[i];
        const url = await uploadFile(image);
        newImageUrls.push(url);
      }
      closeLoading();
      attachImageURLsToProduct([...imageURLs, ...newImageUrls]);
    } catch (error) {
      console.error(error.message);
      if (typeof error == "string") error = { message: error };
      Swal.fire({
        icon: "error",
        title: error.message,
      });
    }
  }

  function onImageChange({ target: { validity, files } }) {
    uploadImagesToAssets(files);
  }

  function handleRemoveImage(imgSrc) {
    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      text: "You are removing the image from being uploaded",
      confirmButtonText: "Yes",
      showDenyButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await attachImageURLsToProduct([
          ...imageURLs.filter((i) => i !== imgSrc),
        ]);
      }
    });
  }
  return (
    <div>
      <input
        className="form-input"
        type="file"
        multiple
        accept="image/*"
        onChange={onImageChange}
      />
      <div className="container">
        <div className="row justify-content-center">
          {imageURLs.map((imgSrc) => (
            <div className="col-sm-12 col-md-4 row position-relative justify-content-center border m-2 p-2">
              <img className="col" src={imgSrc} />
              <Link
                className="remove-image position-absolute d-flex align-items-center justify-content-center h-100"
                to={{}}
                onClick={() => handleRemoveImage(imgSrc)}
              >
                <i className="fa fa-trash"></i>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ImageUploader;
