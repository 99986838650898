import { useQuery } from "@apollo/client";
import React from "react";
import queries from "../queries";
import { ImportMainJs } from "../helper/ImportScript";
import { useEffect } from "react";
import { useState } from "react";
import AddToWishlist from "./mini/AddToWishlist";
import AddToCart from "./mini/AddToCart";
import "./wishlist.css";
import ProductCard from "./mini/ProductCard";
import { setPageTitle } from "../common";
function Wishlist({ refetch: refetchSelf }) {
  const [products, setProducts] = useState([]);

  const {
    data: dataWishlist,
    loading: loadingProducts,
    error: errorProducts,
  } = useQuery(queries.member.GET_SELF);
  useEffect(() => {
    if (dataWishlist != null && dataWishlist.getSelf != null) {
      setProducts([...products, ...dataWishlist.getSelf.wishlist]);
      ImportMainJs();
    }
  }, [dataWishlist]);

  setPageTitle("Wishlist");

  function defaultOptions(product) {
    if (product.options != [] && product.options != null) {
      return product.options.map((option) => {
        return { title: option.title, value: option.values[0].title };
      });
    } else {
      return null;
    }
  }

  if (products.length > 0) {
    return (
      <div className="main">
        <section className="section-md bg-white">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-9 col-xl-10">
                <h3 className="center">Your Wishlist</h3>
                <div className="row row-30">
                  {products &&
                    products.map((product) => {
                      return (
                        <ProductCard product={product} refetch={refetchSelf} />
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  } else {
    return (
      <>
        <section
          className="breadcrumbs-custom"
          data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
        >
          <div className="breadcrumbs-custom-container">
            <ul className="breadcrumbs-custom-path">
              <li>
                <a href="/">Home</a>
              </li>
              <li className="active">Wishlist</li>
            </ul>
          </div>
        </section>
        <section className="section-md bg-white">
          <div className="container">
            <h2 className="text-center">Wishlist</h2>
            <div className="text-center">
              <h5 className="mt-5">
                Your shopping cart is empty. Find your favourite product from
                out huge collection.
              </h5>
              <a className="btn btn-primary text-center" href="/products">
                Shop Now
              </a>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Wishlist;
