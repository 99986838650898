import React from "react";
import { useEffect } from "react";
import { ImportMainJs } from "../helper/ImportScript";
import { setPageTitle } from "../common";

function Error404(props) {
  useEffect(() => {
    ImportMainJs();
  }, []);

  setPageTitle();

  return (
    <>
      <section
        class="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div class="breadcrumbs-custom-container">
          <ul class="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li class="active">Not Found</li>
          </ul>
        </div>
      </section>
      <section class="section-xl bg-gray-100 text-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-8 col-md-10">
              <h1 class="text-decoration">
                The page you're <br class="d-none d-md-block" /> looking for
                doesn't exist<span>404</span>
              </h1>
              <p>
                Either it's been removed or it might be under development.
                <br />
                Sorry for inconvenience.
              </p>
              <a class="btn btn-primary" href="/">
                Back to Home
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Error404;
