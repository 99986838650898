import React from "react";
import Swal from "sweetalert2";

function Footer(props) {
  return (
    <footer className="footer section-lg bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="row row-40">
              <div className="col-xl-5 col-lg-5 col-md-6">
                <h4 className="footer-title">Subscribe Us</h4>
                <form
                  className="rd-form rd-mailform form-sm"
                  data-form-output="form-output-global"
                  data-form-type="subscribe"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="rd-form-inline">
                    <div className="form-wrap">
                      <input
                        className="form-input"
                        id="subscribe-form-email"
                        type="email"
                        name="email"
                        data-constraints="@Email @Required"
                      />
                      <label
                        className="form-label"
                        htmlFor="subscribe-form-email"
                      >
                        ENTER YOUR EMAIL
                      </label>
                    </div>
                    <div className="form-button">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          Swal.fire({
                            icon: "info",
                            title: "Coming soon",
                            text: "We'll soon launch Snoopy Doopy email subscription. Keep in touch to get latest updates.",
                          });
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div className="form-wrap">
                    <label className="shop-checkbox-wrap">
                      <input
                        className="shop-checkbox"
                        type="checkbox"
                        name="brands"
                      />
                      <span className="shop-checkbox-label">
                        Your data will not be transferred to third parties.
                      </span>
                    </label>
                  </div>
                </form>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-4 col-6 offset-xl-1">
                <h4 className="footer-title">About Us</h4>
                <ul className="footer-list">
                  <li className="footer-list-item">
                    <a className="footer-link" href="/products">
                      Shop
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="/about-us">
                      About Store
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="/products">
                      Collections
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="/about-us">
                      Contacts
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="col-lg-2 col-md-6 col-sm-4 col-6">
                <h4 className="footer-title">Support</h4>
                <ul className="footer-list">
                  <li className="footer-list-item">
                    <a className="footer-link" href="#">
                      Delivery
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="#">
                      Payment
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="#">
                      Terms of Use
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="privacy-policy.html">
                      Privacy Policy
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link" href="#">
                      FAQ
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="col-lg-3 col-md-3 col-sm-4">
                <h4 className="footer-title">Contacts</h4>
                <ul className="footer-list">
                  <li className="footer-list-item">
                    19 Meridian Rd, <br />
                    Edison, NJ 08820
                  </li>
                  <li className="footer-list-item">
                    <a className="footer-link-tel" href="tel:7813538922">
                      +1 (781) 353-8922
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a
                      className="footer-link"
                      href="mailto:#stardistributorsllc@gmail.com"
                    >
                      stardistributorsllc@gmail.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-12">
            <div className="row row-15 align-items-center">
              <div className="col-lg-6 text-center text-lg-left">
                <a className="brand" href="index.html">
                  <img src="/images/logo.svg" alt="" width="104" height="22" />
                </a>
              </div>
              <div className="col-lg-6 text-center text-lg-right">
                <p className="rights">
                  <span>&copy;&nbsp;</span>
                  <span className="copyright-year"></span>
                  <span>&nbsp;</span>
                  <span>Roxxe</span>
                  <span>. All rights reserved</span>
                  <span>.&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <a href="/privacy-policy">Privacy Policy</a>
                  <span>.&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <a href="/tnc">Terms of Service</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
