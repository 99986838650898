import { useMutation } from "@apollo/client";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import { isUserLoggedIn, setPageTitle } from "../common";
import queries from "../queries";
import { ImportMainJs } from "../helper/ImportScript";

function ResetPassword(props) {
  const [newPassword, setNewPassword] = useState("");
  const [resetPassword] = useMutation(queries.member.RESET_PASSWORD);
  const [searchParams, setSearchParams] = useSearchParams();

  setPageTitle("Reset Password");

  useEffect(() => {
    if (searchParams.get("token") == null) {
      window.location.href = "/";
    }
    ImportMainJs();
    if (isUserLoggedIn()) {
      Swal.fire({
        icon: "question",
        title: "Reset password?",
        text: "Looks like you are already logged in. Are you trying to reset password for other account?",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem("token");
          window.location.reload();
        } else {
          window.location.href = "/";
        }
      });
    }
  }, []);

  async function handleResetPassword(e) {
    e.preventDefault();
    try {
      if (newPassword.length === 0) {
        throw new Error("Password cannot be empty");
      }
      const { data } = await resetPassword({
        variables: {
          token: searchParams.get("token"),
          newPassword,
        },
      });
      if (data?.resetPassword != null) {
        Swal.fire({
          icon: "success",
          title: data?.resetPassword,
        }).then(() => {
          window.location.href = "/login";
        });
      }
    } catch (e) {
      if (typeof e == "string") {
        e = {
          message: e,
        };
      }
      Swal.fire({
        icon: "error",
        title: e.message,
      });
    }
  }

  return (
    <>
      <section className="section section-md bg-white text-center">
        <div className="container">
          <div className="row justify-content-md-center">
            <div className="col-md-9 col-lg-7 col-xl-5">
              <h3>Reset Password</h3>
              <form className="rd-form rd-mailform rd-form-centered">
                <div className="form-wrap">
                  <input
                    className="form-input"
                    id="new-password"
                    type="password"
                    name="newPassword"
                    data-constraints="@Required"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <label className="form-label" htmlFor="new-password">
                    New Password
                  </label>
                </div>
                <button
                  className="btn btn-block btn-primary"
                  onClick={handleResetPassword}
                >
                  Reset Password
                </button>
              </form>
              <p className="big">
                Don't have an account? <a href="/signup">Signup</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ResetPassword;
