import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import React from "react";
// import PageHeader from "./header/PageHeader";
import queries from "../queries";
import { useEffect } from "react";
import "./wishlist.css";
import {
  calculateSubtotal,
  calculateTaxes,
  calculateTotal,
  getCurrCurrency,
  setPageTitle,
  showLoading,
} from "../common";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ImportMainJs } from "../helper/ImportScript";
import { handleRemoveProductFromCart } from "./mini/AddToCart";

function Cart({ dataSelf, loading, error, refetch }) {
  const navigate = useNavigate();
  const [incQtyToCart] = useMutation(queries.member.INC_QTY_TO_CART);
  const [updateCartOptions] = useMutation(queries.member.UPDATE_CART_OPTIONS);
  const [removeProductFromCart] = useMutation(
    queries.member.REMOVE_PRODUCT_FROM_CART
  );

  setPageTitle("Cart");

  useEffect(() => {
    if (dataSelf?.getSelf != null) {
      ImportMainJs();
    }
  }, [dataSelf]);

  useEffect(() => {
    if (
      error?.networkError?.result?.errors?.[0]?.extensions?.code ==
      "UNAUTHENTICATED"
    ) {
      Swal.fire({
        icon: "error",
        title: "Login to continue",
        text: "You need to be logged in to access cart",
      }).then(() => {
        navigate("/login");
      });
    }
  }, [error]);

  async function handleRemoveCartItemClick(cartId) {
    await handleRemoveProductFromCart(removeProductFromCart, cartId);
    refetch();
  }

  async function incQuantity(cartItem, incBy) {
    try {
      const { data } = await incQtyToCart({
        variables: {
          cartId: cartItem._id,
          incBy: incBy,
        },
      });
      if (data?.incQtyToCart != null) {
        refetch();
      } else {
        throw "Something went wrong!";
      }
    } catch (e) {
      if (typeof e == "string") {
        e = {
          message: e,
        };
      }
      Swal.fire({
        icon: "error",
        title: e.message,
      });
    }
  }

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li className="active">Shopping cart</li>
          </ul>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container">
          <h2 className="text-center">Cart</h2>
          {dataSelf?.getSelf?.cart?.length == 0 && (
            <div className="text-center">
              <h5 className="mt-5">
                Your shopping cart is empty. Find your favourite product from
                out huge collection.
              </h5>
              <a className="btn btn-primary text-center" href="/products">
                Shop Now
              </a>
            </div>
          )}
          {dataSelf?.getSelf?.cart?.length > 0 && (
            <div className="row row-40">
              <div className="col-xl-9 col-lg-8">
                <div className="cart-main">
                  {dataSelf?.getSelf?.cart?.map((cartItem) => {
                    return (
                      <div className="cart-item">
                        <div className="cart-item-row">
                          <figure className="cart-item-col cart-item-col-figure">
                            <a
                              className="link-image rounded"
                              href={`/product/${cartItem?.product_id}`}
                            >
                              <img
                                src={cartItem?.product?.image?.[0]}
                                alt=""
                                width="260"
                                height="312"
                              />
                            </a>
                          </figure>
                          <div className="cart-item-col cart-item-col-content">
                            <div className="cart-item-title">
                              <a href={`/product/${cartItem?.product_id}`}>
                                {cartItem?.product?.name}{" "}
                                {cartItem?.options?.length > 0 ? " - " : ""}
                                {cartItem?.options?.map((o) => o.value + " ")}
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="cart-item-row">
                          <div className="cart-item-col cart-item-col-quantity qty-container">
                            <button
                              className="qty-stepper down"
                              onClick={(e) => {
                                incQuantity(cartItem, -1);
                              }}
                            >
                              -
                            </button>
                            <span className="qty-span">{cartItem?.qty}</span>
                            <button
                              className="qty-stepper up"
                              onClick={(e) => {
                                incQuantity(cartItem, 1);
                              }}
                            >
                              +
                            </button>
                          </div>
                          <div className="cart-item-col cart-item-col-price">
                            {getCurrCurrency()}
                            {cartItem?.price} x {cartItem?.qty} {"= "}
                            <span className="cart-subtitle font-weight-bold">
                              {getCurrCurrency()}
                              {cartItem?.price * cartItem?.qty}
                            </span>
                          </div>
                          <div className="cart-item-col cart-item-col-remove">
                            <a
                              className="cart-item-remove"
                              href="javascript:void(0);"
                              onClick={(e) =>
                                handleRemoveCartItemClick(cartItem?._id)
                              }
                            >
                              <svg
                                className="icon-svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M11.2713 5.86121L9.92761 5.81241L9.6363 13.691L10.98 13.7396L11.2713 5.86121Z"></path>
                                <path d="M8.67226 5.83681H7.32774V13.7154H8.67226V5.83681Z"></path>
                                <path d="M6.36346 13.6908L6.07216 5.81217L4.7285 5.86102L5.01984 13.7396L6.36346 13.6908Z"></path>
                                <path d="M0 2.55222V3.8838H1.40102L2.51247 16.3926C2.54294 16.7363 2.83361 17 3.18205 17H12.7953C13.1438 17 13.4346 16.7361 13.4649 16.3923L14.5764 3.8838H16V2.55222H0ZM12.1795 15.6684H3.79765L2.75071 3.8838H13.2267L12.1795 15.6684Z"></path>
                                <path d="M10.1961 0H5.80392C5.1861 0 4.68348 0.497781 4.68348 1.10965V3.21801H6.028V1.33158H9.972V3.21801H11.3165V1.10965C11.3165 0.497781 10.8139 0 10.1961 0Z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-xl-3 col-lg-4">
                <div className="cart-total-box">
                  <h4 className="cart-total-title font-weight-bold">
                    Cart Total
                  </h4>
                  <div className="cart-total-sub">
                    Subtotal:{" "}
                    <span className="text-primary">
                      {getCurrCurrency()}
                      {calculateSubtotal(dataSelf?.getSelf?.cart)}
                    </span>
                  </div>
                  <div className="cart-total-total">
                    Total:{" "}
                    <span className="text-primary">
                      {getCurrCurrency()}
                      {calculateTotal(dataSelf?.getSelf?.cart)}
                    </span>
                  </div>
                  <a className="btn btn-primary btn-block" href="/checkout">
                    Proceed to checkout
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Cart;
