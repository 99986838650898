import React from "react";
import { setPageTitle } from "../common";

function ContactUs() {
  setPageTitle("Contact Us");
  return (
    <>
      <section className="section section-md bg-white" hidden={false}>
        <div className="container">
          <div className="row row-30">
            <div className="col-lg-4">
              <div className="row row-30">
                <div className="col-6 col-md-4 col-lg-12">
                  <h2>WEED STATION</h2>
                  <div className="contact-info">
                    <p className="contact-info-title">CUSTOMER SERVICE</p>
                    <div className="contact-info-link">
                      <span className="fa-phone mr-2"></span>
                      <a href="tel:#">+09123 456 789</a>
                    </div>
                    <p className="mt-0">
                      Monday to Friday
                      <br />
                      10am - 6.30pm (NewYork time)
                    </p>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-12">
                  <div className="contact-info">
                    <p className="contact-info-title">PRODUCTS & ORDERS</p>
                    <div className="contact-info-link">
                      <span className="fa-phone mr-2"></span>
                      <a href="tel:#">+09123 456 789</a>
                    </div>
                    <p className="mt-0">
                      Monday to Friday
                      <br />
                      10am - 6.30pm (NewYork time)
                    </p>
                  </div>
                </div>
                <div className="col-6 col-md-4 col-lg-12">
                  <div className="contact-info">
                    <p className="contact-info-title">STORE LOCATOR</p>
                    <p>521 Fifth Avenue, Pelham, NY 10783</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="google-map-container" data-key="YOUR_API_KEY">
                <div
                  className="google-map-container"
                  data-center="9870 St Vincent Place, Glasgow, DC 45 Fr 45."
                  data-zoom="5"
                  data-icon="/images/gmap_marker.png"
                  data-icon-active="/images/gmap_marker_active.png"
                  data-styles='[{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":60}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"lightness":30}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ef8c25"},{"lightness":40}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#b6c54c"},{"lightness":40},{"saturation":-40}]},{}]'
                >
                  <div className="google-map"></div>
                  <ul className="google-map-markers">
                    <li
                      data-location="9870 St Vincent Place, Glasgow, DC 45 Fr 45."
                      data-description="9870 St Vincent Place, Glasgow"
                    ></li>
                  </ul>
                </div>
                <h4>
                  Have a question about a product, our company, or just want to
                  chat? Email us!
                </h4>
                <p>
                  We will be glad to assist you in any question and encourage
                  you to share your ideas and improvements with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <p hidden={true}>Hey</p>
    </>
  );
}

export default ContactUs;
