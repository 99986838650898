import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import queries from "../queries";
import { ImportMainJs } from "../helper/ImportScript";
import { useEffect } from "react";
import { useState } from "react";
import AddToWishlist from "./mini/AddToWishlist";
import AddToCart, { handleAddProductToCart } from "./mini/AddToCart";
import {
  closeLoading,
  COUNT_PER_PAGE,
  currency,
  getCurrCurrency,
  isMobileScreen,
  productSortBy,
  productSortByNames,
  productSubtype,
  productSubtypeDisplayNames,
  productType,
  productTypeDisplayNames,
  productTypeURL,
  setPageTitle,
  // shopBanner,
} from "../common";

import {
  Link,
  useSearchParams,
  useParams,
  useNavigate,
} from "react-router-dom";

import "./prodGrid.css";
import { useRef } from "react";
import ProductCard from "./mini/ProductCard";
import Swal from "sweetalert2";

export function getPathFromProduct(product) {
  if (product == null) throw "Invalid Product Info";
  const { name, barcode } = product;
  if (typeof name != "string" || typeof barcode != "string") {
    throw "Invalid Product Info";
  }
  let path = name.split("#")[0].toLowerCase();
  path = path.trim().replaceAll(" ", "-");
  path += "#" + product.barcode;
  return "/product/" + product._id;
}

function ProductGrid({ refetch }) {
  const params = useParams();
  const { type } = params;
  const [products, setProducts] = useState([]);
  const minPriceRef = useRef(null);
  const maxPriceRef = useRef(null);
  const sortByRef = useRef(null);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(searchParams.get("search"));
  const [newSearchTerm, setNewSearchTerm] = useState(searchTerm);
  const [pageProducts, setPageProducts] = useState(1);
  const [isLastPage, setIsLastPage] = useState(false);
  const [filterToggle, setFilterToggle] = useState(true);
  const [minPriceFilter, setMinPriceFilter] = useState();
  const [maxPriceFilter, setMaxPriceFilter] = useState();
  const [delayedMinPriceFilter, setDelayedMinPriceFilter] = useState();
  const [delayedMaxPriceFilter, setDelayedMaxPriceFilter] = useState();
  const [sortBy, setSortBy] = useState(productSortBy.BEST_SELLER);
  const [loadFinishedProduct, setLoadFinishedProduct] = useState(false);

  const [productTypeParam, setProductTypeParam] = useState(() => {
    if (type) {
      return productType[type.toUpperCase()];
    } else {
      return null;
    }
  });
  const [productSubTypeQueryParam, setProductSubTypeQueryParam] = useState(
    () => {
      if (type != null && searchParams.get("subtype")) {
        return productSubtype[type.toUpperCase()][
          searchParams.get("subtype").toUpperCase()
        ];
      } else {
        return null;
      }
    }
  );
  const [productTypes, setProductTypes] = useState(
    new Set(productTypeParam ? [parseInt(productTypeParam)] : [])
  );
  const [productSubTypes, setProductSubTypes] = useState(
    new Set(
      productSubTypeQueryParam ? [parseInt(productSubTypeQueryParam)] : []
    )
  );
  const [addProductToCart] = useMutation(queries.member.ADD_PRODUCT_TO_CART);

  const [displayType, setDisplayType] = useState("grid");
  const [quickViewId, setQuickviewId] = useState(null);
  const [MAX_PRICE_ALLOWED, setMaxPriceAllowed] = useState(200);
  //MAIN QUERY FOR GETTING PRODUCTS
  const {
    data: dataProducts,
    loading: loadingProducts,
    error: errorProducts,
    fetchMore,
  } = useQuery(queries.products.GET_PRODUCTS_BY_QUERY, {
    variables: {
      search: searchTerm,
      page: pageProducts,
      productQuery: {
        type: [...productTypes],
        subtype: [...productSubTypes],
        min_price: delayedMinPriceFilter,
        max_price:
          delayedMaxPriceFilter != MAX_PRICE_ALLOWED
            ? delayedMaxPriceFilter
            : null,
      },
      sortBy: sortBy,
    },
  });
  const {
    data: dataRelatedProducts,
    loading: loadingRelatedProducts,
    error: errorRelatedProducts,
  } = useQuery(queries.products.GET_PRODUCTS_BY_QUERY, {
    variables: {
      page: 1,
      productQuery: {
        type: productTypes.size > 0 ? [...productTypes] : [productType.KRATOM],
      },
      sortBy: productSortBy.MOST_DISCOUNT,
    },
  });

  function handleAddToCartClick(product) {
    const defaultOption = {
      title: product?.options?.[0]?.title,
      value: product?.options?.[0]?.values?.[0]?.title,
    };
    handleAddProductToCart(
      addProductToCart,
      product._id,
      1,
      defaultOption.title != null && defaultOption.value != null
        ? defaultOption
        : null,
      refetch
    );
  }

  useEffect(() => {
    if (searchParams.get("search")?.trim()?.length > 0) {
      setSearchTerm(searchParams.get("search"));
    } else {
      setSearchTerm("");
    }
  }, [searchParams]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayedMinPriceFilter(minPriceFilter);
    }, 1000); // set the delay to 1 second (1000 milliseconds)

    return () => clearTimeout(timeoutId);
  }, [minPriceFilter]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDelayedMaxPriceFilter(maxPriceFilter);
    }, 1000); // set the delay to 1 second (1000 milliseconds)

    return () => clearTimeout(timeoutId);
  }, [maxPriceFilter]);

  useEffect(() => {
    const observerMinPrice = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" &&
          mutation.target === minPriceRef.current
        ) {
          // Content of the div has changed
          handleMinPriceChange(mutation.target.childNodes.item(0).data);
        }
      }
    });

    observerMinPrice.observe(minPriceRef.current, { childList: true });

    const observerMaxPrice = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" &&
          mutation.target === maxPriceRef.current
        ) {
          // Content of the div has changed
          handleMaxPriceChange(mutation.target.childNodes.item(0).data);
        }
      }
    });

    observerMaxPrice.observe(maxPriceRef.current, { childList: true });

    const observerSortBy = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === "childList" ||
          mutation.type === "characterData"
        ) {
          // Content of the div has changed
          const newSortBy = Object.keys(productSortByNames).find(
            (key) =>
              productSortByNames[key] == mutation.target.childNodes.item(0).data
          );
          if (productSortByNames[newSortBy] != null) {
            setSortBy(newSortBy);
          }
        }
      }
    });

    observerSortBy.observe(sortByRef.current, {
      childList: true,
      subtree: true,
      characterData: true,
    });

    setFilterToggle(!isMobileScreen());

    return () => {
      observerMinPrice.disconnect();
      observerMaxPrice.disconnect();
      observerSortBy.disconnect();
    };
  }, []);

  function handleMinPriceChange(minPrice) {
    // Handle the change to the div's content
    if (!isNaN(parseInt(minPrice))) setMinPriceFilter(parseInt(minPrice));
  }

  function handleMaxPriceChange(maxPrice) {
    // Handle the change to the div's content
    if (!isNaN(parseInt(maxPrice))) setMaxPriceFilter(parseInt(maxPrice));
  }

  //APPENDING NEW PRODUCTS DATA TO ALREADY FETCHED DATA
  useEffect(() => {
    if (dataProducts != null && dataProducts.getProductsByQuery != null) {
      ImportMainJs();
      setProducts([...products, ...dataProducts.getProductsByQuery]);
      console.error("Data Err: " + errorProducts);
    } else if (
      dataProducts != null &&
      dataProducts.getProductsByQuery == null
    ) {
      ImportMainJs();
      setPageProducts(pageProducts <= 1 ? 1 : pageProducts - 1);
      if (products?.length > 0) {
        Swal.fire(
          "That's all we got!",
          "We keep adding new products everyday. Stay tuned for new arrivals.",
          "info"
        );
      }
    }
    // setIsLastPage(dataProducts?.getProductsByQuery?.length != COUNT_PER_PAGE);
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });
  }, [dataProducts]);

  //FETCHMORE!
  async function fetchMoreProduct() {
    const { data, errors } = await fetchMore({
      variables: {
        search: searchTerm,
        page: pageProducts,
        productQuery: {
          type: [...productTypes],
          subtype: [...productSubTypes],
          min_price: delayedMinPriceFilter,
          max_price:
            delayedMaxPriceFilter != MAX_PRICE_ALLOWED
              ? delayedMaxPriceFilter
              : null,
        },
        sortBy: sortBy,
      },
    });
    if (errors != null && Array.isArray(errors) && errors.length > 0) {
      if (errors[0].extensions.exception.code == 404) {
        setLoadFinishedProduct(true);
      }
    }
  }

  // useEffect(() => {
  //   fetchMoreProduct();
  // }, [pageProducts]);

  useEffect(() => {
    setProducts([]);
    if (pageProducts != 1) {
      setPageProducts(1);
    } else {
      fetchMoreProduct();
    }
    setLoadFinishedProduct(false);
  }, [searchTerm, sortBy, productTypeParam]);

  useEffect(() => {
    if (type) {
      setPageTitle(type);
    } else if (searchTerm) {
      setPageTitle(searchTerm);
    } else {
      setPageTitle("Best Online Kratom Source");
    }
  }, [searchTerm, type]);

  function defaultOptions(product) {
    if (product.options != [] && product.options != null) {
      return product.options.map((option) => {
        return { title: option.title, value: option.values[0].title };
      });
    } else {
      return null;
    }
  }

  return (
    <>
      <section
        className="breadcrumbs-custom"
        data-preset='{"title":"Breadcrumbs","category":"header","reload":false,"id":"breadcrumbs"}'
      >
        <div className="breadcrumbs-custom-container">
          <ul className="breadcrumbs-custom-path">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a>Shop</a>
            </li>
          </ul>
        </div>
      </section>
      <section className="section-md bg-white">
        <div className="container-xl">
          <div className="row row-40">
            <div className="col-md-2">
              <div className="row product-sidebar-category">
                <div className="col-sm-12">
                  <form
                    className="rd-form rd-search form-sm rd-form-inline-2"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (newSearchTerm?.trim()?.length > 0) {
                        const params = new URLSearchParams(searchParams);
                        params.set("search", newSearchTerm);
                        setSearchParams(params);
                        const newUrl = `${
                          window.location.pathname
                        }?${params.toString()}${window.location.hash}`;
                        navigate(newUrl);
                      } else {
                        const params = new URLSearchParams(searchParams);
                        params.delete("search");
                        setSearchParams(params);
                        const newUrl = `${
                          window.location.pathname
                        }?${params.toString()}${window.location.hash}`;
                        navigate(newUrl);
                      }
                    }}
                  >
                    <div className="form-wrap">
                      <label
                        className="form-label"
                        htmlFor="rd-search-form-input"
                      >
                        SEARCH
                      </label>
                      <input
                        className="form-input"
                        id="rd-search-form-input"
                        type="text"
                        name="s"
                        value={newSearchTerm}
                        onChange={(e) => setNewSearchTerm(e.target.value)}
                        autoComplete="off"
                      />
                    </div>
                    <div className="d-flex">
                      <div className="form-button">
                        <button
                          className="btn btn-primary fa-solid fa-search"
                          type="submit"
                        ></button>
                      </div>
                      {isMobileScreen() && (
                        <div className="form-button ml-3">
                          <button
                            id="btn-filter-toggle"
                            className={`btn btn-primary fa-solid fa-filter no-hover ${
                              filterToggle ? "active" : ""
                            }`}
                            onClick={() => {
                              setFilterToggle(!filterToggle);
                            }}
                          ></button>
                        </div>
                      )}
                    </div>
                  </form>
                </div>
                {filterToggle && (
                  <div>
                    <div className="col-md-12 col-sm-6">
                      <div className="product-sidebar-category-item mt-3">
                        <h6 className="product-sidebar-category-item-title">
                          Product Type
                        </h6>
                        <ul className="product-sidebar-category-item-list">
                          {Object.keys(productType).map((type) => {
                            return (
                              <li key={type}>
                                <label className="shop-checkbox-wrap">
                                  <input
                                    className="shop-checkbox"
                                    type="checkbox"
                                    checked={productTypes.has(
                                      productType[type]
                                    )}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        setProductTypes(
                                          (prevSet) =>
                                            new Set([
                                              ...prevSet,
                                              productType[type],
                                            ])
                                        );
                                      } else {
                                        setProductTypes((prevSet) => {
                                          const newSet = new Set(prevSet);
                                          newSet.delete(productType[type]);
                                          return newSet;
                                        });
                                      }
                                    }}
                                  />
                                  <span className="checkbox-custom-dummy"></span>
                                  <span className="shop-checkbox-label">
                                    {productTypeDisplayNames[productType[type]]}
                                  </span>
                                </label>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    {productTypes.size > 0 && (
                      <div className="col-md-12 col-sm-6">
                        <div className="product-sidebar-category-item">
                          <h6 className="product-sidebar-category-item-title">
                            Product Sub Types
                          </h6>
                          <ul className="product-sidebar-category-item-list">
                            {Object.keys(productSubtypeDisplayNames)
                              .filter((type) =>
                                productTypes.has(productType[type])
                              )
                              .map((type) => {
                                return Object.keys(
                                  productSubtypeDisplayNames[type]
                                ).map((subtypeNum) => {
                                  subtypeNum = parseInt(subtypeNum);
                                  return (
                                    <li key={subtypeNum}>
                                      <label className="shop-checkbox-wrap">
                                        <input
                                          className="shop-checkbox"
                                          type="checkbox"
                                          checked={productSubTypes.has(
                                            subtypeNum
                                          )}
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setProductSubTypes(
                                                (prevSet) =>
                                                  new Set([
                                                    ...prevSet,
                                                    subtypeNum,
                                                  ])
                                              );
                                            } else {
                                              setProductSubTypes((prevSet) => {
                                                const newSet = new Set(prevSet);
                                                newSet.delete(subtypeNum);
                                                return newSet;
                                              });
                                            }
                                          }}
                                        />
                                        <span className="checkbox-custom-dummy"></span>
                                        <span className="shop-checkbox-label">
                                          {
                                            productSubtypeDisplayNames[type][
                                              subtypeNum
                                            ]
                                          }
                                        </span>
                                      </label>
                                    </li>
                                  );
                                });
                              })}
                          </ul>
                        </div>
                      </div>
                    )}

                    <div className="col-md-12 col-sm-6">
                      <div className="product-sidebar-category-item">
                        <h6 className="product-sidebar-category-item-title">
                          Filter by Price
                        </h6>
                        <div className="rd-range-wrap">
                          <div className="rd-range-label">Price:</div>
                          <div className="rd-range-form-wrap">
                            <span>{getCurrCurrency()}</span>
                            <div
                              ref={minPriceRef}
                              className="rd-range-input rd-range-input-value-1"
                            ></div>
                          </div>
                          <div className="rd-range-divider">—</div>
                          <div className="rd-range-form-wrap">
                            <span>{getCurrCurrency()}</span>
                            <div
                              ref={maxPriceRef}
                              className="rd-range-input rd-range-input-value-2"
                            ></div>
                          </div>
                          {maxPriceFilter == MAX_PRICE_ALLOWED && "+"}
                        </div>
                        <div
                          className="rd-range"
                          data-min="0"
                          data-max={MAX_PRICE_ALLOWED}
                          data-start={`[${0}, ${MAX_PRICE_ALLOWED}]`}
                          data-step="1"
                          data-tooltip="false"
                          data-min-diff="5"
                          data-input=".rd-range-input-value-1"
                          data-input-2=".rd-range-input-value-2"
                        ></div>
                      </div>
                    </div>
                    <div className="col-md-12 col-sm-6">
                      <div className="product-sidebar-category-item">
                        <h6 className="product-sidebar-category-item-title">
                          Similar Products
                        </h6>
                        {dataRelatedProducts?.getProductsByQuery
                          ?.slice(0, 3)
                          ?.map((product) => {
                            return (
                              <div className="product-item-small">
                                <figure className="product-item-small-figure">
                                  <a className="link-image rounded-sm" href="#">
                                    <img
                                      src={product?.image?.[0]}
                                      alt=""
                                      width="60"
                                      height="72"
                                    />
                                  </a>
                                </figure>
                                <div className="product-item-small-body">
                                  <div className="product-item-small-title">
                                    <a href="#">{product.name}</a>
                                  </div>
                                  <div
                                    className="product-item-small-rating"
                                    hidden={true}
                                  >
                                    <div className="rating">
                                      <div className="rating-body">
                                        <div className="rating-empty">
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#F0F4F6"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#F0F4F6"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#F0F4F6"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#F0F4F6"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#F0F4F6"
                                            ></path>
                                          </svg>
                                        </div>
                                        <div
                                          className="rating-fill"
                                          style={{ width: "80%" }}
                                        >
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#FFC42D"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#FFC42D"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#FFC42D"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#FFC42D"
                                            ></path>
                                          </svg>
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 13 13"
                                          >
                                            <path
                                              d="M12.9813 5.00878C12.9365 4.87089 12.8174 4.77039 12.6739 4.74957L8.64387 4.16394L6.84153 0.512095C6.77739 0.382095 6.64498 0.299805 6.50002 0.299805C6.35504 0.299805 6.22266 0.382095 6.15849 0.512095L4.35607 4.16394L0.326087 4.74957C0.182656 4.77039 0.0634476 4.87089 0.0186587 5.00876C-0.0261556 5.14665 0.0112193 5.29801 0.115041 5.39919L3.03109 8.24176L2.34281 12.2556C2.31828 12.3985 2.37703 12.5428 2.49431 12.6281C2.56066 12.6763 2.63924 12.7008 2.7182 12.7008C2.77884 12.7008 2.83967 12.6863 2.89538 12.657L6.5 10.7619L10.1045 12.657C10.2328 12.7245 10.3883 12.7133 10.5056 12.628C10.6228 12.5428 10.6816 12.3984 10.6571 12.2555L9.9686 8.24176L12.885 5.39916C12.9888 5.29801 13.0262 5.14665 12.9813 5.00878Z"
                                              fill="#FFC42D"
                                            ></path>
                                          </svg>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="product-item-small-prices pt-2">
                                    <div className="product-item-small-price-old">
                                      {getCurrCurrency()}
                                      {product.old_from_price}
                                    </div>
                                    <div className="product-item-small-price">
                                      {getCurrCurrency()}
                                      {product.from_price}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-10">
              <div className="product-filter">
                <div className="row row-15 justify-content-between align-items-center">
                  <div className="col-auto">
                    {dataProducts?.getProductsByQuery?.length > 0 && (
                      <p className="small">
                        Showing 1-{dataProducts?.getProductsByQuery?.length}{" "}
                        results of page {pageProducts}
                      </p>
                    )}
                    {(dataProducts?.getProductsByQuery == null ||
                      dataProducts?.getProductsByQuery?.length == 0) && (
                      <p className="small">
                        Showing 0 results of page {pageProducts}
                      </p>
                    )}
                  </div>
                  <div className="col-auto">
                    <div className="d-flex align-items-center">
                      <p className="small">Sort by:</p>
                      <div
                        className="select2-outline select2-currency"
                        ref={sortByRef}
                      >
                        <select data-dropdown-class="select-product-filter">
                          {Object.keys(productSortByNames).map((s) => {
                            return (
                              <option key={s}>{productSortByNames[s]}</option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="product-filter-badges">
                <div
                  className="alert fade show product-filter-badge"
                  role="alert"
                >
                  <span>Skin Care</span>
                  <button
                    className="close"
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <svg
                      className="icon-svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.15191 6.00044L1.00044 1.84896C0.766121 1.61465 0.766121 1.23475 1.00044 1.00044C1.23475 0.766121 1.61465 0.766122 1.84896 1.00044L6.00044 5.15191L10.1519 1.00044C10.3862 0.766121 10.7661 0.766121 11.0004 1.00044C11.2348 1.23475 11.2348 1.61465 11.0004 1.84896L6.84896 6.00044L11.0004 10.1519C11.2348 10.3862 11.2348 10.7661 11.0004 11.0004C10.7661 11.2348 10.3862 11.2347 10.1519 11.0004L6.00044 6.84896L1.84896 11.0004C1.61465 11.2347 1.23475 11.2348 1.00044 11.0004C0.766121 10.7661 0.766122 10.3862 1.00044 10.1519L5.15191 6.00044Z"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div
                  className="alert fade show product-filter-badge"
                  role="alert"
                >
                  <span>Body</span>
                  <button
                    className="close"
                    type="button"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <svg
                      className="icon-svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.15191 6.00044L1.00044 1.84896C0.766121 1.61465 0.766121 1.23475 1.00044 1.00044C1.23475 0.766121 1.61465 0.766122 1.84896 1.00044L6.00044 5.15191L10.1519 1.00044C10.3862 0.766121 10.7661 0.766121 11.0004 1.00044C11.2348 1.23475 11.2348 1.61465 11.0004 1.84896L6.84896 6.00044L11.0004 10.1519C11.2348 10.3862 11.2348 10.7661 11.0004 11.0004C10.7661 11.2348 10.3862 11.2347 10.1519 11.0004L6.00044 6.84896L1.84896 11.0004C1.61465 11.2347 1.23475 11.2348 1.00044 11.0004C0.766121 10.7661 0.766122 10.3862 1.00044 10.1519L5.15191 6.00044Z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div> */}
              <div className="row row-30">
                {dataProducts?.getProductsByQuery == null && (
                  <h6 className="ml-3">
                    Uh Oh! No products found. Try using different filter options
                  </h6>
                )}
                {dataProducts?.getProductsByQuery?.map((product) => {
                  return (
                    <div
                      className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12"
                      key={product._id}
                    >
                      <ProductCard product={product} refetch={refetch} />
                    </div>
                  );
                })}
              </div>
              <nav className="pag-classic" aria-label="Page navigation">
                <ul className="pag-classic-list">
                  <li
                    className={`pag-classic-item pag-classic-item-control ${
                      pageProducts <= 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="pag-classic-link"
                      href="javascript:void(0);"
                      onClick={(e) =>
                        setPageProducts(
                          pageProducts <= 1 ? 1 : pageProducts - 1
                        )
                      }
                      aria-label="Previous"
                    >
                      <svg
                        className="icon-svg"
                        width="6"
                        height="10"
                        viewBox="0 0 6 10"
                        fill="none"
                      >
                        <path d="M-0.000488061 4.96484L4.53159 0.432764C4.8097 0.154653 5.26061 0.154653 5.53872 0.432764C5.81683 0.710875 5.81683 1.16178 5.53872 1.43989L1.00664 5.97197L-0.000488061 4.96484Z"></path>
                        <path d="M1.00664 3.95771L5.53872 8.48979C5.81683 8.76791 5.81683 9.21881 5.53872 9.49692C5.26061 9.77503 4.8097 9.77503 4.53159 9.49692L-0.000488061 4.96484L1.00664 3.95771Z"></path>
                      </svg>
                      <span>Prev</span>
                    </a>
                  </li>
                  <li className="pag-classic-item active">
                    <span className="pag-classic-link">
                      {isLastPage
                        ? "Last Page"
                        : pageProducts <= 1
                        ? "First Page"
                        : "Page " + pageProducts}
                    </span>
                  </li>
                  <li
                    className={`pag-classic-item pag-classic-item-control ${
                      isLastPage ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="pag-classic-link"
                      href="javascript:void(0);"
                      onClick={(e) => setPageProducts(pageProducts + 1)}
                      aria-label="Next"
                    >
                      <span>Next</span>
                      <svg
                        className="icon-svg"
                        width="7"
                        height="10"
                        viewBox="0 0 7 10"
                        fill="none"
                      >
                        <path d="M6.0708 4.96484L1.53872 0.432764C1.26061 0.154653 0.809703 0.154653 0.531592 0.432764C0.253481 0.710875 0.253481 1.16178 0.531592 1.43989L5.06367 5.97197L6.0708 4.96484Z"></path>
                        <path d="M5.06367 3.95771L0.531592 8.48979C0.253481 8.76791 0.253481 9.21881 0.531592 9.49692C0.809703 9.77503 1.26061 9.77503 1.53872 9.49692L6.0708 4.96484L5.06367 3.95771Z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductGrid;
