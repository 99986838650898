import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Search(props) {
  const [searchTerm, setSearchTerm] = useState();

  return (
    <div className="rd-navbar-search-wrap">
      <div className="container">
        <div className="rd-navbar-search-title">What are you looking for?</div>
        <div className="rd-search-form-wrap">
          <form
            className="rd-search"
            action="/"
            method="GET"
            onSubmit={(e) => {
              e.preventDefault();
              window.location.href = "/products?search=" + searchTerm;
            }}
          >
            <div className="form-wrap">
              <label
                className="form-label"
                htmlFor="rd-navbar-search-form-input"
              >
                Search for products, brands and more
              </label>
              <input
                className="rd-navbar-search-form-input form-input"
                id="rd-navbar-search-form-input"
                type="text"
                name="s"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="off"
                autoFocus={true}
              />
              <div
                className="rd-search-results-live"
                id="rd-search-results-live"
              ></div>
            </div>
            <button
              className="rd-search-form-submit fa-search"
              type="submit"
            ></button>
          </form>
          <button
            className="modal-close rd-navbar-search-close"
            data-custom-toggle=".rd-navbar-search"
          >
            <svg
              className="icon-svg-outline"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
            >
              <path
                d="M1 1L14 14M27 27L14 14M14 14L27 1M14 14L1 27"
                strokeWidth="1.8"
              ></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Search;
